<template>
    <div data-app>
        <div class="form-group">
            <router-link :to="{name:'usersIndex'}" class="btn btn-primary text-white">{{ i18n.user.Back }}</router-link>
            <router-link :to="'/loginAsUser/'+userId" target="_blank" class="btn btn-danger text-white">{{
                    i18n.user.Login
                }}
            </router-link>
        </div>
        <div class="row">
            <div class="col-md-5 dense">
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ i18n.user.Edit_record }}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-alert color="#f9c9c9" v-if="hasErrors">
                            <div v-for="error in validation_errors">
                                {{ error[0] }}
                            </div>
                        </v-alert>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field v-model="editedItem.login" :label="i18n.user.Login"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field v-model="editedItem.password_raw"
                                                  :label="i18n.user.Password"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field v-model="editedItem.jabber" label="Jabber"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field v-model="editedItem.telegram" label="Telegram"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="8">
                                    <v-select v-model="editedItem.tariff_id" :items="tariffs" item-value="id"
                                              item-text="title" :label="i18n.user.Tariff" attach class="tariffs">
                                        <template slot="selection" slot-scope="data">
                                            <!-- HTML that describe how select should render selected items -->
                                            {{ data.item.title }} [ {{ data.item.price }} ]
                                        </template>
                                        <template slot="item" slot-scope="data">
                                            <!-- HTML that describe how select should render items when the select is open -->
                                            {{ data.item.title }} [ {{ data.item.price }} ]
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-text-field v-model="editedItem.amount" :label="i18n.user.Amount"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field v-model="editedItem.label" :label="i18n.user.Label"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-select v-model="editedItem.active" :items="activities" item-value="id"
                                              item-text="name" :label="i18n.user.Active" attach></v-select>
                                </v-col>
                                <v-col cols="12" sm="12" md="8">
                                    <VueCtkDateTimePicker v-model="editedItem.deadline" format="YYYY-MM-DD HH:mm:ss"/>
                                    <!--v-datetime-picker v-model="editedItem.deadline"
                                                       locale="ru-RU" time-format="HH:mm:ss"
                                                       :label="i18n.user.Paid">
                                        <template slot="dateIcon">
                                            <i class="far fa-calendar-alt">d</i>
                                        </template>
                                        <template slot="timeIcon">
                                            <i class="far fa-clock"></i>
                                        </template>
                                    </v-datetime-picker-->
                                </v-col>
                                <!--v-col cols="12" sm="2" md="2">
                                    <v-btn color="primary" @click="setNow(editedItem.deadline)"><i class="far fa-calendar-check"></i></v-btn>
                                    <!--v-icon @click="deleteItem('active')">mdi-archive-clock</v-icon-->
                                </v-col-->
                                <v-col cols="12" sm="12" md="12">
                                    <v-select v-model="editedItem.proxy_group" :items="groups" item-value="id"
                                              item-text="info" :label="i18n.user.Grpup" attach></v-select>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-text-field v-model="editedItem.date" :label="i18n.user.Registration" readonly
                                                  disabled></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-text-field v-model="editedItem.last_visit" :label="i18n.user.Online" readonly
                                                  disabled></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-card-actions class="mr-4">
                        <v-spacer></v-spacer>
                        <v-btn class="ml-1 btn-danger text-white" text @click="deleteItem()">{{
                                i18n.user.Delete
                            }}
                        </v-btn>
                        <router-link :to="{name:'usersIndex'}" class="btn btn-dark ml-1 text-white text-uppercase">
                            {{ i18n.user.Cancel }}
                        </router-link>
                        <v-btn class="ml-1 btn-primary text-white" text @click="saveNew">{{ i18n.user.Save }}</v-btn>
                    </v-card-actions>
                </v-card>

                <v-card class="mt-2" v-if="editedItem.domain_count>0">
                    <v-card-title>
                        <span class="headline">{{ i18n.user.Proxy }}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-alert color="#f9c9c9" v-if="hasErrors">
                            <div v-for="error in validation_errors">
                                {{ error[0] }}
                            </div>
                        </v-alert>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <v-select v-model="currentProxy" :items="proxy" item-value="id"
                                              item-text="full_title" :label="i18n.user.Proxy" attach></v-select>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="saveProxy">{{ i18n.user.Save }}</v-btn>
                        <v-btn color="red darken-1" text @click="refresh_dnspod">Refresh DNSPOD</v-btn>
                    </v-card-actions>
                </v-card>
                <v-card class="mt-2">
                    <v-card-title>
                        <span class="headline">{{ i18n.user.Proxy_type }}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-alert color="#f9c9c9" v-if="hasErrors">
                            <div v-for="error in validation_errors">
                                {{ error[0] }}
                            </div>
                        </v-alert>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <v-select v-model="proxy_type" :items="proxy_list"
                                              :label="i18n.user.Proxy" attach></v-select>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="saveProxyType">{{ i18n.user.Save }}</v-btn>
                    </v-card-actions>
                </v-card>
                <v-card class="mt-2">
                    <v-card-title>
                        <span class="headline">{{ i18n.user.Additional }}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">{{ i18n.user.Sites }}</v-col>
                                <v-col cols="12" sm="3" md="3">
                                    <v-btn small color="blue darken-1" text @click="addSiteDialog">
                                        {{ i18n.user.Add }}
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" sm="3" md="3">
                                    <v-btn small color="blue darken-1" text @click="listSitesDialog">
                                        {{ i18n.user.List }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">{{ i18n.user.SSL_cert }}</v-col>
                                <v-col cols="12" sm="3" md="3">
                                    <v-btn small color="blue darken-1" text @click="addSSLDialog">
                                        {{ i18n.user.Add }}
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" sm="3" md="3">
                                    <v-btn small color="blue darken-1" text @click="listSSLDialog">
                                        {{ i18n.user.List }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="9" md="9">{{ i18n.user.Domains }}</v-col>
                                <v-col cols="12" sm="3" md="3">
                                    <v-btn small color="blue darken-1" text @click="listDomainsDialog">{{
                                            i18n.user.List
                                        }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <!--v-row>
                                <v-col cols="12" sm="9" md="9">{{ i18n.user.DNS_domains }}</v-col>
                                <v-col cols="12" sm="3" md="3">
                                    <v-btn small color="blue darken-1" text @click="listDNSDomainsDialog">
                                        {{ i18n.user.List }}
                                    </v-btn>
                                </v-col>
                            </v-row-->
                            <v-row>
                                <v-col cols="12" sm="8" md="8">{{ i18n.user.Custom_config }}
                                    <span v-if="haproxyConfig && haproxyConfig.length>0"><i
                                        class="fas fa-tools text-danger"></i></span>
                                </v-col>
                                <v-col cols="12" sm="4" md="4">
                                    <v-btn small color="blue darken-1" text @click="customConfigDialog">
                                        {{ i18n.user.Edit }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </div>
            <div class="col-md-7">
                <!-- Transactions -->
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ i18n.user.List_transactions }}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table :headers="headers" :items="transactions" :items-per-page="40" class="evaluate-1"
                                      :loading="loading" loading-text="Loading... Please wait">
                            <template slot="headerCell" slot-scope="props">
                                <v-tooltip bottom>
                                    <span slot="activator">{{ props.header.text }}</span>
                                    <span>{{ props.header.text }}</span>
                                </v-tooltip>
                            </template>

                            <template v-slot:item.status="{ item }">
                                <span v-if="item.status==1" class="fas fa-check text-success"></span>
                                <span v-else class="fas fa-times text-danger"></span>
                                <v-icon v-if="item.code" small class="mr-2" @click="getTransactionInfo(item)">mdi-information-outline</v-icon>
                            </template>

                            <template v-slot:item.date="{ item }">
                                {{ item.date | formatDateTime }}
                            </template>

                            <template v-slot:item.invoice="{ item }">
                                <a v-if="item.code && item.hash" :href="'https://bitaps.com/ru/'+item.hash"
                                   target="_blank">{{ item.address }}</a>
                                <div v-if="item.code && !item.hash" style="max-width: 200px;">{{ item.address }}</div>
                                <span v-else>{{ item.address ? item.transaction : item.invoice }}</span>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </div>
        </div>

        <!-- Dialogs -->
        <v-dialog v-model="dialogCustomConfig" max-width="600px">
            <v-card class="mt-2">
                <v-card-title>
                    <span class="headline">{{ i18n.user.Switch_to_custom_config }}</span>
                </v-card-title>
                <v-card-text>
                    <v-textarea v-model="haproxyConfig" label="Haproxy config" auto-grow></v-textarea>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeCustomConfigDialog">{{ i18n.user.Close }}</v-btn>
                        <v-btn class="btn-primary text-white ml-1" text @click="saveCustomConfig">
                            {{ i18n.user.Save }}
                        </v-btn>
                    </v-card-actions>

                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogSites" max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ i18n.user.Sites }}</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th class="text-left">#</th>
                                    <th class="text-left">{{ i18n.user.Domain }}</th>
                                    <th class="text-left">IP</th>
                                    <th class="text-left">DNS</th>
                                    <th class="text-left">{{ i18n.user.Options }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="item in listSites" :key="item.id">
                                    <td>{{ item.id }}</td>
                                    <td>
                                        <router-link target="_blank"
                                                     :to="{ name: 'UserSiteEdit', params: { user_id:userId, id: item.id }}">
                                            {{ item.domain }}
                                        </router-link>
                                    </td>
                                    <td>{{ item.ip }}</td>
                                    <td>{{ item.dns }}</td>
                                    <td>
                                        <v-icon small @click="deleteSiteDialog(item)">mdi-delete</v-icon>
                                    </td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeSitesDialog">{{ i18n.user.Close }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogSSL" max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ i18n.user.SSL_certs }}</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th class="text-left">{{ i18n.user.Domain }}</th>
                                    <th class="text-left">SSL</th>
                                    <th class="text-left">{{ i18n.user.Validation }}</th>
                                    <!--th class="text-left">{{ i18n.user.Check }}</th>
                                    <th class="text-left">{{ i18n.user.Options }}</th-->
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="item in listSSL" :key="item.id">
                                    <td>{{ item.domain.replace('@.', '') }}</td>
                                    <td>{{ item.generate == 1 ? 'Letsencrypt' : '' }}</td>
                                    <td>{{ item.status }}</td>
                                    <!--td>
                                        <span v-if="item.revalidate > 0 " class="fas fa-check text-success"></span>
                                        <span v-else class="fas fa-times text-danger""></span>
                                    </td>
                                    <td>
                                        <span v-if="item.verified > 0 " class="fas fa-check text-success"></span>
                                        <span v-else class="fas fa-times text-danger""></span>
                                    </td-->
                                </tr>

                                <!--tr>
                                    <td>
                                        {% if item.verified == 0 and item.revalidate == 0 %}
                                        <button class="btn btn-info btn-sm fas fa-cog" name="ssl_validation" data-id="{{ item.ssl_id }}"></button>
                                        <button class="btn btn-primary btn-sm" name="ssl_confirm_validation" data-id="{{ item.id }}">{{ t("confirmation") }}</button>
                                        {% endif %}

                                        {% if item.verified == 0 and item.revalidate > 0 %}
                                        <button class="btn btn-light btn-sm" name="ssl_validation_status" data-id="{{ item.id }}">{{ t("check") }}</button>
                                        <button class="btn btn-light btn-sm" name="ssl_validation_reset" data-id="{{ item.id }}">{{ t("cancel") }}</button>
                                        {% endif %}
                                    </td>
                                </tr-->
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeSSLDialog">{{ i18n.user.Close }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDomains" max-width="700px">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ i18n.user.Domains }}</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th class="text-left">{{ i18n.user.Domain }}</th>
                                    <th class="text-left">DNS 1</th>
                                    <th class="text-left">DNS 2</th>
                                    <th class="text-left">{{ i18n.user.Date }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="item in listDomains.domain_reg" :key="item.id">
                                    <td>{{ item.domain }}</td>
                                    <td>{{ item.ns_1 }}</td>
                                    <td>{{ item.ns_2 }}</td>
                                    <td>{{ item.date | formatDate }}</td>
                                </tr>
                                <tr v-for="item in listDomains.domain_webnic" :key="item.id">
                                    <td>{{ item.domain }}</td>
                                    <td>{{ item.ns_1 }}</td>
                                    <td>{{ item.ns_2 }}</td>
                                    <td>{{ item.date | formatDate }}</td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDomainsDialog">{{ i18n.user.Close }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDNSDomains" max-width="700px">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ i18n.user.Domains }}</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th class="text-left">#</th>
                                    <th class="text-left">{{ i18n.user.Domain }}</th>
                                    <th class="text-left">{{ i18n.user.Status }}</th>
                                    <th class="text-left">{{ i18n.user.Active }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="item in listDNSDomains" :key="item.id">
                                    <td>{{ item.id }}</td>
                                    <td>{{ item.domain }}</td>
                                    <td>{{ item.status }}</td>
                                    <td>
                                        <span v-if="item.active > 0" class="fas fa-check text-success"></span>
                                        <span v-else class="fas fa-times text-danger"></span>
                                    </td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDNSDomainsDialog">{{ i18n.user.Close }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogAddSSL" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                    {{ i18n.user.Add_generate_ssl }}
                </v-btn>
            </template>
            <v-card>
                <v-card-title>
                    <span class="headline">{{ i18n.user.New_SSL }}</span>
                </v-card-title>
                <v-alert color="#f9c9c9" v-if="hasErrors">
                    <div v-for="error in validation_errors">
                        {{ error[0] }}
                    </div>
                </v-alert>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-select v-model="editedItem.domain_id" @change="setSubdomains" :items="SSLdomains"
                                          item-value="elid" item-text="domain" :label="i18n.user.Domain"
                                          clearable></v-select>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-select v-model="editedItem.record_id" :items="subdomains" item-value="record_id"
                                          item-text="name" :label="i18n.user.Subdomain" clearable></v-select>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-checkbox v-model="editedItem.generate" :label="i18n.user.LetsEncrypt"
                                            class="d-inline-block"></v-checkbox>
                                <v-tooltip left attach>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" class="pb-1">mdi-help-circle</v-icon>
                                    </template>
                                    <span>{{ i18n.user.Generate_letsencrypt_ssl }}</span>
                                </v-tooltip>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" v-if="!editedItem.generate">
                                <v-textarea v-model="editedItem.crt" class="cert" rows="8"
                                            :label="i18n.user.Certificate"></v-textarea>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" v-if="!editedItem.generate">
                                <v-textarea v-model="editedItem.private" class="cert" rows="8"
                                            :label="i18n.user.Private_key"></v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeAddSSLDialog">{{ i18n.user.Cancel }}</v-btn>
                    <v-btn color="blue darken-1" text @click="saveNewSSL">{{ i18n.user.Save }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogAddDomain" max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ i18n.user.New_item }}</span>
                </v-card-title>

                <v-card-text>
                    <v-alert color="#f9c9c9" v-if="hasErrors">
                        <div v-for="error in validation_errors">
                            {{ error[0] }}
                        </div>
                    </v-alert>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field v-model="newDomain.domain" :label="i18n.user.Domain"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field v-model="newDomain.ip" :label="i18n.user.IP"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-combobox multiple v-model="newDomain.port" :label=i18n.user.Inp_Port
                                            append-icon></v-combobox>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-combobox multiple v-model="newDomain.port_out" :label=i18n.user.Out_Port
                                            append-icon></v-combobox>
                            </v-col>
                            <!--v-col cols="12" sm="12" md="12">
                                <v-select v-model="newDomain.dns" :items="dns_list" :label="i18n.user.DNS"
                                          attach></v-select>
                            </v-col-->
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="saveNewDomain">{{ i18n.user.Save }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">{{ i18n.user.You_want_remove }}</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">{{ i18n.user.Cancel }}</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{ i18n.user.ok }}</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDeleteSite" max-width="500px">
            <v-card>
                <v-card-title class="headline">{{ i18n.user.You_want_remove }}</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDeleteSiteDialog">{{ i18n.user.Cancel }}</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteSiteConfirm">{{ i18n.user.ok }}</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogLog" max-width="500px">
            <v-card>
                <v-card-title class="headline">{{ i18n.user.Show_transaction_info }}</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="overflow-x-auto" style="height: 600px;">
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th>Amount</th>
                                <th>Confirmations</th>
                                <th>time</th>
                            </tr>
                            </thead>
                        <tbody v-for="item in log.callback_list">
                            <tr>
                                <td>{{ item.amount }}</td>
                                <td>{{ item.confirmations }}</td>
                                <td>{{ item.time }}</td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialogs -->
        <v-overlay :value="overlay" z-index="2000">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>

</template>

<script>
export default {
    created() {
        let app = this;
        let id = app.$route.params.id;
        this.getGroups();
        this.getTariffs();
        this.getTransactions(id);
        this.getFreeProxy(id);
        this.getCustomConfig(id);

    },
    mounted() {
        let app = this;
        let id = app.$route.params.id;
        app.userId = id;
        axios.get('/api/v1/users/' + id + '/edit')
            .then(function (resp) {
                app.editedItem = resp.data;
                app.proxy_type = app.editedItem.proxy_type;
                //app.editedItem.deadline = app.formatDate(app.editedItem.deadline);
                app.loading = false;
                console.log(app.editedItem.deadline);
            })
            .catch(function () {
                app.loading = false;
                alert("Не удалось загрузить")
            });
        axios.get('/api/v1/users/' + id + '/sites')
            .then(function (resp) {
                app.domains = resp.data;
                console.log(app.domains);
            })
            .catch(function (resp) {
                alert("Loading error");
            });
        axios.get('/api/v1/users/' + id + '/sites/subdomains/a')
            .then(function (resp) {
                app.all_subdomains = resp.data;
            })
            .catch(function (resp) {
                alert("Loading error");
            });
    },
    data: function () {
        return {
            validation_errors: [],
            hasErrors: false,
            overlay: false,
            currentProxy: 0,
            haproxyConfig: '',
            haproxyConfigActive: 0,
            newDomain: {},
            groups: [],
            tariffs: [],
            proxy: [],
            list: [],
            userId: 0,
            transactions: [],
            listSSL: [],
            listSites: [],
            listDomains: [],
            listDNSDomains: [],
            SSLdomains: [],
            all_subdomains: [],
            subdomains: [],
            removedSite: false,
            loading: true,
            dialogSites: false,
            dialogDomains: false,
            dialogDNSDomains: false,
            dialogAddDomain: false,
            dialogAddSSL: false,
            dialogCustomConfig: false,
            dialogDeleteSite: false,
            dialogLog: false,
            dialogSSL: false,
            dialogDelete: false,
            log: '',
            dns_list: ['dnspod', 'custom'],
            proxy_list: ['haproxy', 'nginx'],
            proxy_type: '',
            editedItem: {
                login: '',
                password: '',
                jabber: '',
                telegram: '',
                label: '',
                balance: '',
                proxy_group: '',
                deadline: '',
                active: '',
                tariff_id: '',
            },
            defaultItem: {
                login: '',
                password: '',
                jabber: '',
                telegram: '',
                label: '',
                balance: '',
                proxy_group: '',
                deadline: '',
                active: '',
                tariff_id: 15,
            },
            headers: [
                {text: i18n.user.Amount, value: 'amount', width: '100px'},
                {text: i18n.user.OperationAddress, value: 'invoice', width: '90%'},
                {text: i18n.user.Status, sortable: true, value: 'status', width: '5px'},
                {text: i18n.user.Date, sortable: true, value: 'date', width: '150px'}
            ],
            activities: [
                {'id': 0, 'name': i18n.user.switch_off},
                {'id': 1, 'name': i18n.user.switch_on},
            ]
        }
    },
    methods: {
        setSubdomains: function (domain_id) {
            this.subdomains = this.all_subdomains[domain_id];
        },
        setNow: function (obj) {
            let d = new Date();
            let str = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate() + ' ' + d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds();
            this.editedItem.deadline = str;
            return false;
        },
        formatDate: function (mysqlDate) {
            return mysqlDate.replace(' ', 'T');
        },
        saveProxyType() {
            let app = this;
            this.overlay = true;
            axios.put('/api/v1/users/' + app.editedItem.id + '/proxy_type/', {
                proxy_type: app.proxy_type,
            })
                .then(function (resp) {
                    app.overlay = false;
                })
                .catch(function (resp) {
                    console.log(resp);
                    app.overlay = false;
                    alert("Modify proxy type error");
                });
        },
        saveCustomConfig: function () {
            let app = this;
            app.overlay = true;
            axios.put('/api/v1/users/' + app.editedItem.id + '/custom_config', {
                user_id: app.editedItem.id,
                text: app.haproxyConfig,
            })
                .then(function (resp) {
                    app.validation_errors = [];
                    app.overlay = false;
                    app.hasErrors = false;
                    app.dialogCustomConfig = false;
                })
                .catch(function (resp) {
                    app.validation_errors = resp.response.data.errors;
                    app.hasErrors = true;
                    app.overlay = false;
                    app.dialogCustomConfig = false;
                });
        },
        listSitesDialog: function () {
            let app = this;
            axios.get('/api/v1/users/' + app.userId + '/sites')
                .then((response) => {
                    app.listSites = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
            this.dialogSites = true;
        },
        closeSitesDialog: function () {
            this.dialogSites = false;
        },
        addSSLDialog: function () {
            //alert ('Еще не работает');
            let app = this;
            axios.get('/api/v1/users/' + app.userId + '/sites')
                .then((response) => {
                    app.SSLdomains = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
            this.dialogAddSSL = true;
        },
        saveNewSSL() {
            let app = this;
            app.overlay = true;
            //console.log(app.editedItem);
            axios.post('/api/v1/users/' + app.userId + '/ssl_keys/create', {
                id: app.editedItem.id,
                domain_id: app.editedItem.domain_id,
                record_id: app.editedItem.record_id,
                generate: app.editedItem.generate,
                crt: app.editedItem.crt,
                private: app.editedItem.private,
            })
                .then(function (resp) {
                    app.list = resp.data
                    app.validation_errors = [];
                    app.hasErrors = false;
                    app.editedItem = app.defaultItem;
                    app.overlay = false;
                    app.closeAddSSLDialog()
                })
                .catch(function (resp) {
                    app.validation_errors = resp.response.data.errors;
                    app.hasErrors = true;
                    app.overlay = false;
                    console.log(app.validation_errors);
                    //alert("Update item error");
                });
        },
        closeAddSSLDialog: function () {
            this.dialogAddSSL = false;
        },
        addSiteDialog: function () {
            //alert ('Еще не работает');
            this.dialogAddDomain = true;
        },
        closeCustomConfigDialog: function () {
            this.dialogCustomConfig = false;
        },
        customConfigDialog: function () {
            this.dialogCustomConfig = true;
        },
        closeDeleteSiteDialog: function () {
            this.dialogDeleteSite = false;
        },
        deleteSiteDialog: function (item) {
            this.removedSite = item.id;
            this.dialogDeleteSite = true;
        },
        deleteSiteConfirm() {
            var app = this;
            app.overlay = true;
            axios.delete('/api/v1/users/' + app.userId + '/site/' + app.removedSite + '/delete')
                .then(function (resp) {
                    app.listSites = resp.data;
                    app.overlay = false;
                })
                .catch(function (resp) {
                    console.log(resp);
                    app.overlay = false;
                    //alert("Remove item error");
                });
            this.closeDeleteSiteDialog()
        },
        listSitesDialog: function () {
            let app = this;
            axios.get('/api/v1/users/' + app.userId + '/sites')
                .then((response) => {
                    app.listSites = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
            this.dialogSites = true;
        },
        listSSLDialog: function () {
            let app = this;
            axios.get('/api/v1/users/' + app.userId + '/ssls')
                .then((response) => {
                    app.listSSL = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
            this.dialogSSL = true;
        },
        closeSSLDialog: function () {
            this.dialogSSL = false;
        },
        listDomainsDialog: function () {
            let app = this;
            axios.get('/api/v1/users/' + app.userId + '/domains')
                .then((response) => {
                    app.listDomains = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
            this.dialogDomains = true;
        },
        closeDomainsDialog: function () {
            this.dialogDomains = false;
        },
        closeDelete: function () {
            this.dialogDelete = false
        },
        listDNSDomainsDialog: function () {
            let app = this;
            axios.get('/api/v1/users/' + app.userId + '/dns_domains')
                .then((response) => {
                    app.listDNSDomains = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
            this.dialogDNSDomains = true;
        },
        closeDNSDomainsDialog: function () {
            this.dialogDNSDomains = false;
        },
        getGroups: function () {
            let app = this;
            axios.get('/api/v1/proxy_group')
                .then((response) => {
                    //this.groups  = response.data;
                    app.groups = [{
                        id: 0,
                        name: i18n.user.Any_element,
                        info: i18n.user.Any_element
                    }].concat(response.data);
                    console.log(app.groups);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getTariffs: function () {
            axios.get('/api/v1/tariff')
                .then((response) => {
                    this.tariffs = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getTransactionInfo: function(item) {
            axios.get('/api/v1/users/transaction/'+item.address+'/'+item.code)
                .then((response) => {
                    this.dialogLog = true;
                    this.log = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getTransactions: function (id) {
            axios.get('/api/v1/users/' + id + '/transactions')
                .then((response) => {
                    this.transactions = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getCustomConfig: function (id) {
            axios.get('/api/v1/users/' + id + '/custom_config')
                .then((response) => {
                    this.haproxyConfig = response.data.text;
                    this.haproxyConfigActive = response.data.active;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getFreeProxy: function (id) {
            let app = this;
            axios.get('/api/v1/free_proxy/' + id)
                .then((response) => {
                    this.proxy = response.data;
                    for (let p in this.proxy) {
                        if (this.proxy[p].user_id) {
                            this.currentProxy = this.proxy[p].id;
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        saveNew() {
            let app = this;
            //axios.put('/api/v1/users/' + id, {
            axios.put('/api/v1/users/' + app.editedItem.id, {
                id: app.editedItem.id,
                login: app.editedItem.login,
                password_raw: app.editedItem.password_raw,
                jabber: app.editedItem.jabber,
                telegram: app.editedItem.telegram,
                active: app.editedItem.active,
                proxy_group: app.editedItem.proxy_group,
                tariff_id: app.editedItem.tariff_id,
                label: app.editedItem.label,
                amount: app.editedItem.amount,
                deadline: app.editedItem.deadline,
            })
                .then(function (resp) {
                    app.list = resp.data
                    app.validation_errors = [];
                    app.hasErrors = false;
                    //app.editedItem = app.defaultItem;
                    alert(i18n.user.Saved);
                })
                .catch(function (resp) {
                    app.validation_errors = resp.response.data.errors;
                    app.hasErrors = true;
                });
            //this.$router.push({name: 'usersIndex'});
        },
        saveProxy() {
            let app = this;
            app.overlay = true;
            axios.put('/api/v1/users/' + app.editedItem.id + '/proxy', {
                id: app.editedItem.id,
                proxy: app.currentProxy,
            })
                .then(function (resp) {
                    app.list = resp.data
                    app.validation_errors = [];
                    app.hasErrors = false;
                    app.overlay = false;
                    alert(i18n.user.Proxy_saved);
                })
                .catch(function (resp) {
                    app.validation_errors = resp.response.data.errors;
                    app.overlay = false;
                    app.hasErrors = true;
                });
        },
        refresh_dnspod() {
            let app = this;
            app.overlay = true;
            axios.put('/api/v1/users/' + app.editedItem.id + '/refresh_dnspod', {
                id: app.editedItem.id,
            })
                .then(function (resp) {
                    app.validation_errors = [];
                    app.hasErrors = false;
                    app.overlay = false;
                    alert('DNSPOD ');
                })
                .catch(function (resp) {
                    app.validation_errors = resp.response.data.errors;
                    app.overlay = false;
                    app.hasErrors = true;
                });
        },
        deleteItem() {
            this.dialogDelete = true
        },
        deleteItemConfirm() {
            this.list.splice(this.editedIndex, 1)
            var app = this;
            axios.delete('/api/v1/users/' + app.editedItem.id)
                .then(function (resp) {
                })
                .catch(function (resp) {
                    console.log(resp);
                    alert("Remove item error");
                });
            this.closeDelete()
            this.$router.push({name: 'usersIndex'});
        },
        saveNewDomain() {
            let app = this;
            app.overlay = true;
            axios.post('/api/v1/users/' + app.editedItem.id + '/sites/create', {
                user_id: app.editedItem.id,
                domain: app.newDomain.domain,
                ip: app.newDomain.ip,
                //dns: app.newDomain.dns,
                dns: 'dnspod',
                port: app.newDomain.port.join(','),
                port_out: app.newDomain.port_out.join(','),
            })
                .then(function (resp) {
                    app.validation_errors = [];
                    app.hasErrors = false;
                    app.editedItem = app.defaultItem;
                    app.overlay = false;
                    app.dialogAddDomain = false;
                })
                .catch(function (resp) {
                    app.validation_errors = resp.response.data.errors;
                    app.hasErrors = true;
                    app.overlay = false;
                    app.dialogAddDomain = false;
                    console.log(app.validation_errors);
                });
        },
    }
}
</script>
