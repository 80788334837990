<template>
    <div data-app class="v-application">
        <div class="panel panel-default">
            <div class="panel-body">
                <div class="row">
                    <div class="col-md-12">
                        <v-card>
                            <v-card-title>
                                <span class="headline">{{ formTitle }}</span>
                            </v-card-title>

                            <v-card-text>
                                <v-alert color="#f9c9c9" v-if="hasErrors">
                                    <div v-for="error in validation_errors">
                                        {{ error[0] }}
                                    </div>
                                </v-alert>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="12" md="3">
                                            <v-text-field v-model="editedItem.domain" disabled :label="i18n.user.Domain"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="3">
                                            <v-text-field v-model="editedItem.ip" :label="i18n.user.IP"></v-text-field>
                                        </v-col>
                                        <v-col cols="6" md="3">
                                            <div v-if="proxy_type.proxy_type=='haproxy'">
                                            <v-checkbox  v-model="editedItem.public_ip" :label="i18n.user.https_send_proxy"  class="d-inline-block"></v-checkbox>
                                            <v-tooltip right attach>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon  v-on="on"  class="pb-1">mdi-help-circle</v-icon>
                                                </template>
                                                <span>{{ i18n.user.Get_proxy_from_your_server }}</span>
                                            </v-tooltip>
                                            </div>
                                        </v-col>
                                        <v-col cols="6" md="3">
                                            <v-checkbox v-model="editedItem.wildcard" :label="i18n.user.Wildcard" class="d-inline-block"></v-checkbox>
                                            <v-tooltip right attach>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon v-on="on"  class="pb-1">mdi-help-circle</v-icon>
                                                </template>
                                                <span>{{ i18n.user.Wildcard_help }}</span>
                                            </v-tooltip>
                                        </v-col>
                                        <v-col cols="6" md="2">
                                            <v-combobox multiple v-model="editedItem.port" :label=i18n.user.Inp_Port append-icon :validate-on-blur="validatePorts()"></v-combobox>
                                        </v-col>
                                        <v-col cols="6" md="2">
                                            <v-combobox multiple v-model="editedItem.port_out" :label=i18n.user.Out_Port append-icon :validate-on-blur="validatePorts()"></v-combobox>
                                        </v-col>
                                        <v-col cols="6" md="2">
                                            <v-combobox multiple v-model="editedItem.port_ssl" :label=i18n.user.Inp_Port_SSL append-icon :validate-on-blur="validatePorts()"></v-combobox>
                                        </v-col>
                                        <v-col cols="6" md="2">
                                            <v-combobox multiple v-model="editedItem.port_out_ssl" :label=i18n.user.Out_Port_SSL append-icon :validate-on-blur="validatePorts()"></v-combobox>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4">
                                            <v-select v-model="editedItem.dns" disabled :items="dns_list" :label="i18n.user.DNS" attach></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row v-for="record in list" v-bind:key="record.id">
                                        <v-col cols="12" sm="12" md="12"><v-divider></v-divider></v-col>
                                        <v-col cols="12" sm="12" class="d-md-flex flex-row">
                                            <v-text-field dense v-model="record.name" :label="i18n.user.DNS_record" class="mr-2 mw-100px"></v-text-field>
                                            <v-select dense v-model="record.type" :items="dns_types" :label="i18n.user.Type" class="mr-2 mw-100px" attach></v-select>
                                            <v-combobox dense multiple v-if="record.type=='A'" v-model="record.port" class="mr-2 mw-100px" :label=i18n.user.Inp_Port append-icon></v-combobox>
                                            <v-combobox dense multiple v-if="record.type=='A'" v-model="record.port_ssl" class="mr-2 mw-170px" :label=i18n.user.Inp_Port_SSL append-icon></v-combobox>
                                            <v-combobox dense multiple v-if="record.type=='A'" v-model="record.port_out" class="mr-2 mw-10px" :label=i18n.user.Out_Port append-icon></v-combobox>
                                            <v-combobox dense multiple v-if="record.type=='A'" v-model="record.port_out_ssl" class="mr-2 mw-170px" :label=i18n.user.Out_Port_SSL append-icon></v-combobox>
                                            <v-text-field dense v-model="record.record" class="mr-2" :label="record.type=='A' ? i18n.user.IP : i18n.user.Info"></v-text-field>
                                            <div class="notice" v-if="record.type=='A'">{{ i18n.user.If_empty_IP_use_IP_domain}}</div>
                                            <v-btn fab small color="pink" @click="deleteRecord(record.id)">
                                                <v-icon class="text-white">mdi-delete</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>

                                    <v-row v-for="(new_record, index) in new_list" v-bind:key="index">
                                        <v-col cols="12" sm="12" md="12"><v-divider></v-divider></v-col>
                                        <v-col cols="12" sm="12" class="d-md-flex flex-row">
                                            <v-text-field dense v-model="new_record.name" :label="i18n.user.DNS_record" class="mr-2 mw-100px"></v-text-field>
                                            <v-select dense v-model="new_record.type" :items="dns_types" :label="i18n.user.Type" class="mr-2 mw-100px" attach></v-select>
                                            <v-combobox dense multiple v-if="new_record.type=='A'" v-model="new_record.port" class="mr-2 mw-100px" :label=i18n.user.Inp_Port append-icon></v-combobox>
                                            <v-combobox dense multiple v-if="new_record.type=='A'" v-model="new_record.port_ssl" class="mr-2 mw-170px" :label=i18n.user.Inp_Port_SSL append-icon></v-combobox>
                                            <v-combobox dense multiple v-if="new_record.type=='A'" v-model="new_record.port_out" class="mr-2 mw-10px" :label=i18n.user.Out_Port append-icon></v-combobox>
                                            <v-combobox dense multiple v-if="new_record.type=='A'" v-model="new_record.port_out_ssl" class="mr-2 mw-170px" :label=i18n.user.Out_Port_SSL append-icon></v-combobox>
                                            <v-text-field dense v-model="new_record.new_record" class="mr-2" :label="new_record.type=='A' ? i18n.user.IP : i18n.user.Info"></v-text-field>
                                            <div class="notice" v-if="new_record.type=='A'">{{ i18n.user.If_empty_IP_use_IP_domain}}</div>
                                            <v-btn fab small color="pink" @click="deleteNewRecord(index)">
                                                <v-icon class="text-white">mdi-delete</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="12" md="11">&nbsp;</v-col>
                                        <v-col cols="12" sm="12" md="1">
                                            <v-btn fab small color="blue" @click="addNewRecord()">
                                                <v-icon class="text-white">mdi-format-annotation-plus</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-dialog v-model="dialogDelete" max-width="500px">
                                <v-card>
                                    <v-card-title class="headline">{{ i18n.user.You_want_remove }}</v-card-title>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="white darken-1" class="btn-primary mr-2" text @click="closeDelete">{{ i18n.user.Cancel }}</v-btn>
                                        <v-btn color="white darken-1" class="btn-danger" text @click="deleteDomainConfirm">{{ i18n.user.Delete }}</v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="white darken-1" class="btn-danger mr-1" text @click="deleteDomain">{{ i18n.user.Delete }}</v-btn>
                                <v-btn color="white darken-1" class="primary" text @click="save" :disabled="!filled_form">{{ i18n.user.Save }}</v-btn>
                            </v-card-actions>
                        </v-card>
                    </div>
                </div>
            </div>
        </div>
        <v-overlay :value="overlay" z-index="2000">
            <v-progress-circular indeterminate size="64" ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                dialog: false,
                dialogNew: false,
                dialogDelete: false,
                validation_errors: [],
                hasErrors: false,
                proxy_type: {proxy_type: 'haproxy'},
                filled_form: false,
                overlay: false,
                domain_id: 0,
                headers: [
                    { text: '#', align: 'start', value: 'id', width: '30px', sortable: false },
                    { text: i18n.user.Domain, value: 'domain', width: '24%', sortable: false },
                    { text: i18n.user.IP, value: 'ip', width: '24%', sortable: false },
                    { text: i18n.user.DNS, value: 'dns', width: '24%', sortable: false },
                    { text: i18n.user.Date, value: 'date', width: '24%', sortable: false },
                ],
                list: [],
                new_list: [],
                dns_list:['dnspod','custom'],
                dns_types:['A','CNAME','TXT','MX'],
                ports: [],
                editedIndex: -1,
                editedItem: {
                    port: ['80'],
                    port_out: ['80'],
                    port_ssl: ['443'],
                    port_out_ssl: ['443'],
                    dns: 'dnspod',
                },
                defaultItem: {
                    port: ['80'],
                    port_out: ['80'],
                    port_ssl: ['443'],
                    port_out_ssl: ['443'],
                    dns: 'dnspod',
                },
                default_new_record: {
                    name: '',
                    type: 'A',
                    port: ['80'],
                    port_out: ['80'],
                    port_ssl: ['443'],
                    port_out_ssl: ['443'],
                }
            }
        },
        computed: {
            formTitle () {
                return i18n.user.Edit_item
            },
        },
        created() {
        },
        mounted() {
            var app = this;
            let id = app.$route.params.id;
            axios.get('/api/v1/account/settings')
                .then(function (resp) {
                    app.proxy_type = resp.data;
                })
                .catch(function (resp) {});
            axios.get('/api/v1/account/sites/' + id+'/edit')
                .then(function (resp) {
                    app.editedItem = resp.data;
                    app.domain_id = app.editedItem.elid;
                    app.default_new_record.domain_id = app.domain_id;
                    app.reload(app, app.editedItem.elid);
                })
                .catch(function () {
                    alert("Loading error")
                });

        },
        methods: {
            alert(obj) {
                console.log(obj);
                alert(obj);
            },
            validatePorts() {
                this.filled_form = (!!this.editedItem.port.join('')) &&
                    (!!this.editedItem.port_out.join('')) &&
                    (!!this.editedItem.port_ssl.join('')) &&
                    (!!this.editedItem.port_out_ssl.join(''));
            },
            reload(app, elid) {
                axios.get('/api/v1/account/sites/' + elid + '/records')
                    .then(function (resp) {
                        app.list = resp.data;
                    })
                    .catch(function (resp) {
                        alert("Loading error");
                    });
            },
            addNewRecord() {
                let o = Object.assign({}, this.default_new_record);
                this.new_list.push(o);
                console.log(this.new_list);
            },
            deleteNewRecord(idx) {
                this.new_list.splice(idx,1);
            },
            deleteRecord(idx) {
                this.overlay = true;
                let app = this;
                axios.delete('/api/v1/account/sites/' + app.domain_id + '/records/destroy?ids='+idx, null )
                    .then(function (resp) {
                        app.list = resp.data
                        app.overlay = false;
                    })
                    .catch(function (resp) {
                        app.validation_errors = resp.response.data.errors;
                        app.hasErrors = true;
                        app.overlay = false;
                        console.log(app.validation_errors);
                    });
            },
            closeDelete () {
                this.dialogDelete = false
            },
            deleteDomain () {
                this.dialogDelete = true
            },
            deleteDomainConfirm() {
                this.overlay = true;
                let app = this;
                this.closeDelete()
                axios.delete('/api/v1/account/sites/' + app.domain_id, null )
                    .then(function (resp) {
                        app.overlay = false;
                        app.$router.push({name:'account.Sites'});

                    })
                    .catch(function (resp) {
                        app.validation_errors = resp.response.data.errors;
                        app.hasErrors = true;
                        app.overlay = false;
                        console.log(app.validation_errors);
                    });
            },
            save () {
                let app = this;
                app.validation_errors = [];
                app.hasErrors = false;
                this.overlay = true;
                axios.post('/api/v1/account/sites/' + app.editedItem.id + '/update', {
                    id: app.editedItem.id,
                    ip: app.editedItem.ip,
                    public_ip: app.editedItem.public_ip,
                    wildcard: app.editedItem.wildcard,
                    port: app.editedItem.port ? app.editedItem.port.join(',') : '',
                    port_out: app.editedItem.port_out ? app.editedItem.port_out.join(',') : '',
                    port_ssl: app.editedItem.port_ssl ? app.editedItem.port_ssl.join(',') : '',
                    port_out_ssl: app.editedItem.port_out_ssl ? app.editedItem.port_out_ssl.join(',') : '',
                })
                .then(function (resp) {
                    console.log(resp.data);
                    app.editedItem = resp.data
                    if (typeof app.editedItem.port !=='Array') {
                        app.editedItem.port = app.editedItem.port.split(',');
                    }
                    if (typeof app.editedItem.port_out !=='Array') {
                        app.editedItem.port_out = app.editedItem.port_out.split(',');
                    }
                    if (typeof app.editedItem.port_ssl !=='Array') {
                        app.editedItem.port_ssl = app.editedItem.port_ssl.split(',');
                    }
                    if (typeof app.editedItem.port_out_ssl !=='Array') {
                        app.editedItem.port_out_ssl = app.editedItem.port_out_ssl.split(',');
                    }
                    axios.post('/api/v1/account/sites/' + app.editedItem.elid + '/records/update', app.list )
                        .then(function (resp) {
                            app.list = resp.data

                            axios.post('/api/v1/account/sites/' + app.editedItem.elid + '/records/create', app.new_list )
                                .then(function (resp) {
                                    app.reload(app, app.editedItem.elid);
                                    app.new_list = []
                                    app.overlay = false;
                                })
                                .catch(function (resp) {
                                    app.validation_errors = resp.response.data.errors;
                                    app.hasErrors = true;
                                    app.overlay = false;
                                });
                        })
                        .catch(function (resp) {
                            app.validation_errors = resp.response.data.errors;
                            app.hasErrors = true;
                            app.overlay = false;
                        });
                })
                .catch(function (resp) {
                    app.validation_errors = resp.response.data.errors;
                    app.hasErrors = true;
                    app.overlay = false;
                });
            },
        }
    }
</script>
