<template>
    <div data-app>
        <div class="panel panel-default">
            <div class="panel-body">
                <v-data-table :headers="headers" :items="list" :items-per-page="50" class="evaluate-1" :loading="loading"
                              loading-text="Loading... Please wait" @click:row="editItem">
                    <!-- Dialogs -->
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>{{ i18n.user.Transactions }}</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-divider class="mx-4" inset vertical></v-divider>
                        </v-toolbar>
                    </template>
                    <!-- Dialogs -->

                    <template v-slot:item.date="{ item }">
                        {{ item.date | formatDateTime }}
                    </template>

                    <template v-slot:no-data>
                        <v-btn color="primary" @click="initialize">Reset</v-btn>
                    </template>

                </v-data-table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data: function () {
        return {
            host: location.host,
            inviteLink: '',
            loading: true,
            headers: [
                {text: '#', align: 'start', value: 'id', width: '30px', sortable: false},
                {text: i18n.user.User, value: 'login', width: '10%'},
                { text: i18n.user.Amount, value: 'amount', width: '100px' },
                { text: i18n.user.OperationAddress, value: 'invoice', width: '90%' },
                { text: i18n.user.Status, sortable: true, value: 'status', width:'5px' },
                { text: i18n.user.Date, sortable: true, value: 'date', width:'150px' }
            ],
            list: [],
        }
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? i18n.user.New_item : i18n.user.Edit_item
        },
    },
    created() {
    },
    mounted() {
        var app = this;
        axios.get('/api/v1/transactions')
            .then(function (resp) {
                app.list = resp.data;
                app.loading = false;
            })
            .catch(function (resp) {
                app.loading = false;
                alert("Loading error");
            });
    },
    methods: {
        getColor(active) {
            return active == 1 ? 'text-success' : 'text-danger';
        },
    }
}
</script>
