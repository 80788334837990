<template>
    <div data-app>
        <div class="panel panel-default">
            <div class="panel-body">
                <div class="row">
                    <div class="col-md-7">
                        <v-alert color="#f9c9c9" v-if="hasNotices">
                            {{ i18n.user.You_have_stoped_VPS }}
                        </v-alert>
                        <v-data-table :headers="headers" :items="list" :items-per-page="50"  class="elevation-1" loading :loading-text=i18n.user.Empty  no-data-text="Empty">
                            <template slot="headerCell" slot-scope="props">
                                <v-tooltip bottom>
                                    <span slot="activator">
                                    {{ props.header.text }}
                                    </span>
                                    <span>
                                    {{ props.header.text }}
                                    </span>
                                </v-tooltip>
                            </template>
                            <!--template v-slot:item.active="{ item }">
                                <i class="fas fa-power-off" :class="getColor(item.active)"></i>
                            </template-->

                            <template v-slot:item.deadline="{ item }">
                                {{ item.deadline | formatDate }}
                            </template>

                            <template v-slot:item.date="{ item }">
                                {{ item.date | formatDate }}
                            </template>

                            <template v-slot:item.actions="{ item }">
                                <v-icon small @click="renewItem(item)" :title=i18n.user.Pay>mdi-credit-card-outline</v-icon>
                                <v-icon small v-if="item.active" @click="manageItem(item)">mdi-cog-outline</v-icon>
                                <v-icon small v-if="!item.active" class="text-danger">mdi-cog-outline</v-icon>
                                <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
                            </template>
                            <template v-slot:no-data>
                                <v-btn color="primary" @click="initialize">Reset</v-btn>
                            </template>

                        </v-data-table>
                    </div>
                    <div class="col-md-5">
                            <v-card>
                                <v-card-title>
                                    <span class="headline">{{ i18n.user.New_VPS }}</span>
                                </v-card-title>

                                <v-card-text>
                                    <v-alert color="#f9c9c9" v-if="hasErrors">
                                        <div v-for="error in validation_errors">
                                            {{ error[0] }}
                                        </div>
                                    </v-alert>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" md="12">
                                                <v-select v-model="newItem.tariff" item-value="template" item-text="tariff" :label="i18n.user.Tariff" :items="tariffs_list"  attach>
                                                    <template slot="item"  slot-scope="data">
                                                        <v-row><v-col cols="12" md="7">{{ data.item.tariff }}</v-col>
                                                            <v-col cols="12" md="5" class="align-right"><b>${{ data.item.amount }} / {{ i18n.user.month }}</b></v-col></v-row>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" md="12">
                                                <v-select v-model="newItem.os" item-value="os" item-text="os" :label="i18n.user.OS" :items="os_list"  attach></v-select>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="saveNew">{{ i18n.user.Save }}</v-btn>
                                </v-card-actions>
                            </v-card>
                    </div>
                </div>
            </div>
        </div>
        <v-overlay :value="overlay" z-index="2000">
            <v-progress-circular indeterminate size="64" ></v-progress-circular>
        </v-overlay>
        <v-dialog v-model="dialog" max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ i18n.user.VPS_manage }}</span>
                </v-card-title>

                <v-card-text>
                    <v-container class="pb-5">
                        <v-row>
                            <v-col cols="10" md="10"><v-select class="ml-3" dense v-model="editedItem.os" item-value="os" item-text="os" :label="i18n.user.VPS_reinstall" :items="os_list"  attach></v-select></v-col>
                            <v-col cols="2" md="2"><v-icon class=" btn pb-1" @click="reinstallVPS();">mdi-autorenew</v-icon></v-col>
                            <v-col cols="12" md="6" v-if="vm_info.vm.state=='down'"><v-icon class="btn pb-1" @click="startVPS();">mdi-play-circle</v-icon> {{ i18n.user.VPS_start }}</v-col>
                            <v-col cols="12" md="6" v-if="vm_info.vm.state=='running'"><v-icon class="btn pb-1"  @click="stopVPS();">mdi-stop-circle</v-icon> {{ i18n.user.VPS_stop }}</v-col>
                            <v-col cols="12" md="6"><v-icon class=" btn pb-1"  @click="rebootVPS();">mdi-reload</v-icon> {{ i18n.user.VPS_reboot }}</v-col>
                            <v-col cols="12" md="7"><v-icon class=" btn pb-1"  @click="showVPSpassword();">mdi-eye</v-icon> {{ i18n.user.VPS_password_show }}</v-col>
                            <v-col cols="12" md="5"><v-text-field v-model="root_password" readonly disabled ></v-text-field></v-col>

                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">{{ i18n.user.Close }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline"> {{ i18n.user.You_want_remove}}</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogRenew" max-width="500px">
            <v-card>
                <v-card-title class="headline"> {{ i18n.user.You_want_renew_VPS}}</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeRenew">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="renewItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                dialog: false,
                dialogNew: false,
                dialogDelete: false,
                dialogRenew: false,
                validation_errors: [],
                hasErrors: false,
                hasNotices: false,
                overlay: false,
                inviteLink: '',
                root_password: '',
                vm_info: {
                    'vm': {'state':''},
                    'isp': {}
                },
                headers: [
                    { text: '#', align: 'start', value: 'id', width: '30px', sortable: false },
                    { text: i18n.user.Tariff, value: 'tariff', width: '19%', sortable: false },
                    { text: i18n.user.IP, value: 'ip', width: '19%', sortable: false },
                    { text: i18n.user.OS, value: 'os', width: '24%', sortable: false },
                    { text: i18n.user.Paid_to, value: 'date_end', width: '34%', sortable: false },
                    { text: i18n.user.Actions, sortable: false, value: 'actions', width:'1%' }
                ],
                list: [],
                tariffs_list:[],
                os_list: [],
                editedIndex: -1,
                editedItem: {
                },
                newItem: {
                    os: '',
                    tariff:'',
                },
                defaultItem: {},
            };
        },
        computed: {
            formTitle () {
                return this.editedIndex === -1 ? i18n.user.New_VPS : i18n.user.Edit_VPS
            },
        },
        created() {
        },
        mounted() {
            var app = this;
            axios.get('/api/v1/account/vps')
                .then(function (resp) {
                    app.list = resp.data;
                    for (let idx in app.list) {
                        if (app.list[idx].active===0) {
                            app.hasNotices = true;
                        }
                    }
                })
                .catch(function (resp) {
                    alert("Loading error");
                });
            axios.get('/api/v1/vps_tariff')
                .then(function (resp) {
                    app.tariffs_list = resp.data;
                })
                .catch(function (resp) {
                    alert("Loading error");
                });
            axios.get('/api/v1/account/vps/os_list')
                .then(function (resp) {
                    app.os_list = resp.data;
                })
                .catch(function (resp) {
                    alert("Loading error");
                });
        },
        methods: {
            saveNew () {
                let app = this;
                app.overlay = true;
                axios.post('/api/v1/account/vps/create', {
                    tariff: app.newItem.tariff,
                    os: app.newItem.os,
                })
                    .then(function (resp) {
                        console.log(resp.data);
                        app.list = resp.data
                        app.validation_errors = [];
                        app.hasErrors = false;
                        app.overlay = false;
                        app.editedItem = app.defaultItem;
                    })
                    .catch(function (resp) {
                        app.validation_errors = resp.response.data.errors;
                        app.hasErrors = true;
                        app.overlay = false;
                        console.log(app.validation_errors);
                        //alert("Update item error");
                    });
            },
            manageItem(item) {
                let app = this;
                app.overlay = true;
                this.editedIndex = this.list.indexOf(item)
                this.editedItem = Object.assign({}, item)
                axios.get('/api/v1/account/vps/' + this.editedItem.id + '/info')
                    .then(function (resp) {
                        app.vm_info = resp.data;
                        app.dialog = true
                        app.overlay = false;
                    })
                    .catch(function (resp) {
                        app.overlay = false;
                        alert("Loading error");
                    });
            },
            close() {
                this.dialog = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },
            deleteItem(item) {
                this.editedIndex = this.list.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialogDelete = true
            },
            deleteItemConfirm() {
                this.list.splice(this.editedIndex, 1)
                var app = this;
                app.overlay = true;
                axios.delete('/api/v1/account/vps/' + app.editedItem.id)
                    .then(function (resp) {
                        app.overlay = false;
                    })
                    .catch(function (resp) {
                        console.log(resp);
                        app.overlay = false;
                        alert("Remove item error");
                    });
                this.closeDelete()
            },
            closeDelete () {
                this.dialogDelete = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },
            renewItem(item) {
                this.editedIndex = this.list.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialogRenew = true
            },
            renewItemConfirm() {
                var app = this;
                app.overlay = true;
                app.dialogRenew = false
                axios.get('/api/v1/account/vps/' + this.editedItem.id + '/renew')
                    .then(function (resp) {
                        app.list = resp.data;
                        app.hasErrors = false;
                        app.overlay = false;
                    })
                    .catch(function (resp) {
                        app.validation_errors = resp.response.data.errors;
                        app.hasErrors = true;
                        app.overlay = false;
                    });
            },
            closeRenew () {
                this.dialogRenew = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },
            startVPS() {
                var app = this;
                app.overlay = true;
                axios.get('/api/v1/account/vps/' + this.editedItem.id + '/start')
                    .then(function (resp) {
                        app.vm_info = resp.data;
                        app.overlay = false;
                    })
                    .catch(function (resp) {
                        alert("Loading error");
                        app.overlay = false;
                    });
            },
            stopVPS() {
                var app = this;
                app.overlay = true;
                axios.get('/api/v1/account/vps/' + this.editedItem.id + '/stop')
                    .then(function (resp) {
                        app.vm_info = resp.data;
                        app.overlay = false;
                    })
                    .catch(function (resp) {
                        alert("Loading error");
                        app.overlay = false;
                    });
            },
            reinstallVPS() {
                var app = this;
                app.overlay = true;
                axios.get('/api/v1/account/vps/' + this.editedItem.id + '/reinstall/'+this.editedItem.os)
                    .then(function (resp) {
                        app.vm_info = resp.data;
                        app.overlay = false;
                    })
                    .catch(function (resp) {
                        alert("Loading error");
                        app.overlay = false;
                    });
            },
            rebootVPS() {
                var app = this;
                app.overlay = true;
                axios.get('/api/v1/account/vps/' + this.editedItem.id + '/restart')
                    .then(function (resp) {
                        app.vm_info = resp.data;
                        app.overlay = false;
                    })
                    .catch(function (resp) {
                        alert("Loading error");
                        app.overlay = false;
                    });
            },
            showVPSpassword() {
                var app = this;
                app.overlay = true;
                axios.get('/api/v1/account/vps/' + this.editedItem.id + '/password/show')
                    .then(function (resp) {
                        app.root_password = resp.data;
                        app.overlay = false;
                    })
                    .catch(function (resp) {
                        alert("Loading error");
                        app.overlay = false;
                    });
            },
        }
    }
</script>
