<template>
    <div data-app>
        <div class="panel panel-default">
            <div class="panel-body">
                <v-toolbar flat>
                    <v-toolbar-title>{{ i18n.user.News}}</v-toolbar-title>
                </v-toolbar>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                list: [],
            }
        },
        computed: {
        },
        mounted() {
            var app = this;
            axios.get('/api/v1/account/news')
                .then(function (resp) {
                    app.list = resp.data;
                })
                .catch(function (resp) {
                    alert("Loading error");
                });
        },
        methods: {
        }
    }
</script>
