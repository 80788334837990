<template>
    <div data-app>
        <div class="panel panel-default">
            <div class="panel-body">
                <v-data-table :headers="headers" :items="list" :items-per-page="itemsPerPage" class="evaluate-1"
                              :loading="loading" loading-text="Loading... Please wait" @click:row="editItem">
                    <!-- Dialogs -->
                    <template v-slot:top>

                        <div class="row">
                            <div class="col-12 col-md-3">
                                <v-toolbar-title>{{ i18n.user.Users_list }}</v-toolbar-title>
                            </div>
                            <div class="col-12 col-md-6">
                                <v-row class="mt-2">
                                    <v-col cols="12" sm="12" md="9">
                                        <v-text-field v-model="inviteLink" :label="i18n.user.InviteLink"
                                                      readonly></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3">
                                        <v-btn color="primary" dark class="__mb-2" @click="getInvite">{{
                                                i18n.user.Get
                                            }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                            <div class="col-12 col-md-3 pt-5">
                                <v-dialog v-model="dialogNew" max-width="500px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="primary" dark class="mt-3" v-bind="attrs" v-on="on">
                                            {{ i18n.user.New_item }}
                                        </v-btn>
                                    </template>
                                    <v-card>
                                        <v-card-title>
                                            <span class="headline">{{ formTitle }}</span>
                                        </v-card-title>

                                        <v-card-text>
                                            <v-alert color="#f9c9c9" v-if="hasErrors">
                                                <div v-for="error in validation_errors">
                                                    {{ error[0] }}
                                                </div>
                                            </v-alert>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" sm="12" md="6">
                                                        <v-text-field v-model="editedItem.login"
                                                                      :label="i18n.user.Login"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="6">
                                                        <v-text-field v-model="editedItem.password_raw" append-outer-icon="mdi-key-variant"  @click:append-outer="generatePassword"
                                                                      :label="i18n.user.Password"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="6">
                                                        <v-text-field v-model="editedItem.jabber"
                                                                      label="Jabber"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="6">
                                                        <v-text-field v-model="editedItem.telegram"
                                                                      label="Telegram"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="12">
                                                        <v-select v-model="editedItem.tariff" :items="tariffs"
                                                                  item-value="id" item-text="title"
                                                                  :label="i18n.user.Tariff" attach></v-select>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="blue darken-1" text @click="closeNew">
                                                {{ i18n.user.Cancel }}
                                            </v-btn>
                                            <v-btn color="blue darken-1" text @click="saveNew">
                                                {{ i18n.user.Save }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                                <v-dialog v-model="dialog" max-width="500px">
                                    <v-card>
                                        <v-card-title>
                                            <span class="headline">{{ formTitle }}</span>
                                        </v-card-title>
                                        <v-card-text>
                                            <div class="alert">
                                                <div class="alert" v-for="error in validation_errors">
                                                    {{ error }}
                                                </div>
                                            </div>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" sm="12" md="6">
                                                        <v-text-field v-model="editedItem.login"
                                                                      :label="i18n.user.Login"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="6">
                                                        <v-text-field v-model="editedItem.password_raw"
                                                                      :label="i18n.user.Password"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="6">
                                                        <v-text-field v-model="editedItem.jabber"
                                                                      label="Jabber"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="6">
                                                        <v-text-field v-model="editedItem.telegram"
                                                                      label="Telegram"></v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="blue darken-1" text @click="close">{{ i18n.user.Cancel }}
                                            </v-btn>
                                            <v-btn color="blue darken-1" text @click="save">{{ i18n.user.Save }}</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                                <v-dialog v-model="dialogDelete" max-width="500px">
                                    <v-card>
                                        <v-card-title class="headline">{{ i18n.user.You_want_remove }}</v-card-title>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="blue darken-1" text @click="closeDelete">{{
                                                    i18n.user.Cancel
                                                }}
                                            </v-btn>
                                            <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{
                                                    i18n.user.ok
                                                }}
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </div>
                        </div>

                    </template>
                    <!-- Dialogs -->

                    <template v-slot:body="props">
                        <tbody>
                        <tr v-for="(item, index) in list" :key="item.id" @click="editItem(item)" :class="ending(item)">
                            <td class="text-start"> {{ item.id }}</td>
                            <td class="text-start">
                                <div @click.stop="return false;">
                                    <b @click.stop="loadDomains(item)">
                                        <i v-if="item.role=='root'" class="fas fa-crown"></i>
                                        {{ item.login }}
                                    </b>
                                    <ul v-if="current_user==item.id && sites.length>0 && current_user_show==item.id">
                                        <li v-for="site in sites" :key="site.id">
                                            <router-link
                                                :to="{ name: 'UserSiteEdit', params: { user_id:item.id, id: site.id }}">
                                                {{ site.domain }} [{{ site.ip }}]
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                            <td class="text-start"> {{ item.amount }}</td>
                            <td class="text-start"> {{ item.domain_count }}</td>
                            <td class="text-start"> {{ item.tariff }}</td>
                            <td class="text-start"><span v-if="item.active==1" class="fas fa-check text-success"></span>
                                <span v-else class="fas fa-times text-danger"></span></td>
                            <td class="text-start"> {{ item.proxy_type }} <span v-if="item.custom_config"><i
                                class="fas fa-tools text-danger"></i></span></td>
                            <td class="text-start"><span v-if="item.auto_renew==1"
                                                         class="fas fa-check text-success"></span>
                                <span v-else class="fas fa-times text-danger"></span></td>
                            <td class="text-start deadline"> {{ item.deadline | formatDate }}</td>
                            <td class="text-start"> {{ item.date | formatDate }}</td>
                            <td class="text-start"> {{ item.last_visit | formatDate }}</td>

                        </tr>
                        </tbody>
                    </template>

                    <template slot="headerCell" slot-scope="props">
                        <v-tooltip bottom>
                            <span slot="activator">
                            {{ props.header.text }}
                            </span>
                            <span>
                            {{ props.header.text }}
                            </span>
                        </v-tooltip>
                    </template>
                    <!--template v-slot:item.active="{ item }">
                        <i class="fas fa-power-off" :class="getColor(item.active)"></i>
                    </template-->

                    <template v-slot:item.login="{ item }">
                        <div @click.stop="loadDomains(item)">
                            <b>{{ item.login }}</b>
                            <ul v-if="current_user==item.id && sites.length>0">
                                <li v-for="site in sites" :key="site.id">
                                    <router-link
                                        :to="{ name: 'UserSiteEdit', params: { user_id:item.id, id: site.id }}">
                                        {{ site.domain }} [{{ site.ip }}]
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </template>

                    <template v-slot:item.deadline="{ item }">
                        {{ item.deadline | formatDate }}
                    </template>

                    <template v-slot:item.date="{ item }">
                        {{ item.date | formatDate }}
                    </template>

                    <template v-slot:item.last_visit="{ item }">
                        {{ item.last_visit | formatDate }}
                    </template>

                    <template v-slot:item.active="{ item }">
                        <span v-if="item.active==1" class="fas fa-check text-success"></span>
                        <span v-else class="fas fa-times text-danger"></span>
                    </template>

                    <template v-slot:item.dnspod="{ item }">
                        <span v-if="item.dnspod==1" class="fas fa-check text-success"></span>
                        <span v-else class="fas fa-times text-danger"></span>
                    </template>

                    <template v-slot:item.auto_renew="{ item }">
                        <span v-if="item.auto_renew==1" class="fas fa-check text-success"></span>
                        <span v-else class="fas fa-times text-danger"></span>
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
                        <v-icon small @click="deleteItem(item)" v-if="item.used_count==0">mdi-delete</v-icon>
                    </template>
                    <template v-slot:no-data>
                        <v-btn color="primary" @click="initialize">Reset</v-btn>
                    </template>

                </v-data-table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data: function () {
        return {
            dialog: false,
            loading: true,
            dialogNew: false,
            dialogDelete: false,
            itemsPerPage: 50,
            sites: [],
            current_user: 0,
            current_user_show: 0,
            current_select: false,
            validation_errors: [],
            prev_item_active: -1,
            hasErrors: false,
            host: location.host,
            inviteLink: '',
            headers: [
                {text: '#', align: 'start', value: 'id', width: '30px', sortable: false},
                {text: i18n.user.Login, value: 'login', width: '15%'},
                {text: i18n.user.Amount, value: 'amount', width: '1%'},
                {text: i18n.user.Sites, value: 'domain_count', width: '50px', sortable: false},
                {text: i18n.user.Tariff, value: 'tariff', width: '10%', sortable: false},
                {text: i18n.user.Status, value: 'active', width: '5%', sortable: false},
                {text: i18n.user.Proxy, value: 'proxy_type', width: '10%', sortable: false},
                {text: i18n.user.Renew, value: 'auto_renew', width: '1%', sortable: false},
                {text: i18n.user.Paid, value: 'deadline', width: '100px'},
                {text: i18n.user.Register, value: 'date', width: '10%', sortable: false},
                {text: i18n.user.Online, value: 'last_visit', width: '100px', sortable: false},
            ],
            list: [],
            tariffs: [],
            editedIndex: -1,
            editedItem: {
                login: '',
                password_raw: '',
            },
            defaultItem: {
                name: ''
            },
        }
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? i18n.user.New_item : i18n.user.Edit_item
        },
    },
    created() {
    },
    mounted() {
        var app = this;
        axios.get('/api/v1/users')
            .then(function (resp) {
                app.list = resp.data;
                app.loading = false;
                for (let i in app.list) {
                    app.sites['user_' + app.list[i].id] = [{id: 111}];
                }
            })
            .catch(function (resp) {
                app.loading = false;
                alert("Loading error");
            });
        axios.get('/api/v1/users/tariffs/')
            .then(function (resp) {
                app.tariffs = resp.data;
            })
            .catch(function (resp) {
                console.error("Loading error");
            });
    },
    methods: {
        generatePassword: function () {
            let charactersArray = 'a-zA-Z0-9'.split(',')
            let CharacterSet = ''
            let password = ''
            let size = 14

            charactersArray = 'a-z,A-Z,0-9'.split(',')

            if (charactersArray.indexOf('a-z') >= 0) {
                CharacterSet += 'abcdefghijklmnopqrstuvwxyz'
            }
            if (charactersArray.indexOf('A-Z') >= 0) {
                CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
            }

            if (charactersArray.indexOf('0-9') >= 0) {
                CharacterSet += '0123456789'
            }
            if (charactersArray.indexOf('#') >= 0) {
                CharacterSet += '![]{}()%&*$#^~@|'
            }
            for (let i = 0; i < size; i++) {
                password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length))
            }
            this.editedItem.password_raw = password;
            this.$clipboard(password)
            this.copyTextToClipboard(password);
            return true;
        },
        fallbackCopyTextToClipboard: function (text) {
            let textArea = document.createElement("textarea");
            textArea.value = text;

            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'successful' : 'unsuccessful';
                console.log('Fallback: Copying text command was ' + msg);
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }

            document.body.removeChild(textArea);
        },
        copyTextToClipboard: function (text) {
            if (!navigator.clipboard) {
                this.fallbackCopyTextToClipboard(text);
                return;
            }
            navigator.clipboard.writeText(text).then(function () {
                console.log('Async: Copying to clipboard was successful!');
            }, function (err) {
                console.error('Async: Could not copy text: ', err);
            });
        },
        ending: function (item) {
            let deadline = window.moment(item.deadline);
            let now = window.moment();
            let days = Math.round((deadline - now) / (1000 * 60 * 60 * 24));
            //console.log(item.deadline + ':' + now + ' = ' + days);
            let prevIndex = this.list.indexOf(item) - 1;
            if (prevIndex >= 0 && this.list[prevIndex].active === 1 && item.active === 0) {
                return 'ended_line';
            }
            if (item.active === 0) {
                return 'ended';
            }
            if (days >= 0 && days < 5) {
                return 'ending';
            }
            return '';
        },
        getGroups: function () {
            axios.get('/api/v1/proxy_group')
                .then((response) => {
                    this.groups = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        editItem(item) {
            this.$router.push({name: 'usersEdit', params: {id: item.id}});
            /*this.editedIndex = this.list.indexOf(item)
            this.editedItem = Object.assign({}, item)
            console.log(this.editedItem);
            this.dialog = true*/
        },

        deleteItem(item) {
            this.editedIndex = this.list.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            this.list.splice(this.editedIndex, 1)
            var app = this;
            axios.delete('/api/v1/users/' + app.editedItem.id)
                .then(function (resp) {
                })
                .catch(function (resp) {
                    console.log(resp);
                    alert("Remove item error");
                });
            this.closeDelete()
        },

        getInvite() {
            this.list.splice(this.editedIndex, 1)
            var app = this;
            axios.get('/api/v1/users/invite')
                .then(function (resp) {
                    app.inviteLink = location.host + "/account/signup/" + resp.data.auth_key;
                })
                .catch(function (resp) {
                    console.log(resp);
                    alert("Remove item error");
                });
            this.closeDelete()
        },

        getColor(active) {
            return active == 1 ? 'text-success' : 'text-danger';
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeNew() {
            this.dialogNew = false
            this.$nextTick(() => {
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        loadDomains(item) {
            let app = this;
            if (app.current_user_show == item.id) {
                app.current_user_show = 0;
                app.current_select = false;
            } else {
                app.current_user_show = item.id;
            }
            if (app.current_user == item.id) {
                return false;
            }
            app.current_user = item.id;
            app.sites = [];
            axios.get('/api/v1/users/' + item.id + '/sites')
                .then((response) => {
                    app.sites = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
            return false;
        },
        save() {
            if (this.editedIndex > -1) {

                let app = this;
                console.log("ID:::" + this.editedItem.user);
                axios.put('/api/v1/users/' + app.editedItem.id, {
                    id: app.editedItem.id,
                    login: app.editedItem.login,
                    password_raw: app.editedItem.password_raw,
                    jabber: app.editedItem.jabber,
                    telegram: app.editedItem.telegram,
                })
                    .then(function (resp) {
                    })
                    .catch(function (resp) {
                        console.log(resp);
                        alert("Update item error");
                    });

            } else {
                this.list.push(this.editedItem)
            }
            Object.assign(this.list[this.editedIndex], this.editedItem)
            this.close()
        },
        saveNew() {
            let app = this;
            axios.post('/api/v1/users/create', {
                id: app.editedItem.id,
                login: app.editedItem.login,
                password_raw: app.editedItem.password_raw,
                jabber: app.editedItem.jabber,
                telegram: app.editedItem.telegram,
                tariff_id: app.editedItem.tariff,
            })
                .then(function (resp) {
                    app.list = resp.data
                    app.validation_errors = [];
                    app.hasErrors = false;
                    app.editedItem = app.defaultItem;
                    app.closeNew()
                })
                .catch(function (resp) {
                    app.validation_errors = resp.response.data.errors;
                    app.hasErrors = true;
                    console.log(app.validation_errors);
                    //alert("Update item error");
                });


        },
    }
}
</script>
