<template>
    <div data-app>
        <div class="panel panel-default">
            <div class="panel-body">
                <v-data-table :headers="headers" :items="list" :items-per-page="50" class="evaluate-1"
                              :loading="loading"
                              loading-text="Loading... Please wait">
                    <!-- Dialogs -->
                    <template v-slot:body="props">
                        <tbody>
                        <tr v-for="(item, index) in list" :key="item.id">
                            <td class="text-start"> {{ item.id }}</td>
                            <td class="text-start"> {{ item.login }}</td>
                            <td class="text-start"> {{ item.task }}</td>
                            <td class="text-start"> {{ item.target }}</td>
                            <td class="text-start" style="overflow: hidden; max-width: 200px;"> {{ item.message }}</td>
                            <td class="text-start"> {{ item.date | formatDateTime }}</td>
                        </tr>
                        </tbody>
                    </template>

                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>{{ i18n.user.Logs }}</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-btn color="primary" dark class="mb-2" @click="clearAllLogs();">
                                {{ i18n.user.Clear }}
                            </v-btn>
                        </v-toolbar>
                    </template>
                    <!-- Dialogs -->

                    <template v-slot:item.date="{ item }">
                        {{ item.date | formatDateTime }}
                    </template>

                    <template v-slot:no-data>
                        <v-btn color="primary" @click="initialize">Reset</v-btn>
                    </template>

                </v-data-table>
                <v-overlay :value="overlay" z-index="2000">
                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                </v-overlay>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data: function () {
        return {
            host: location.host,
            inviteLink: '',
            loading: true,
            overlay: false,
            headers: [
                {text: '#', align: 'start', value: 'id', width: '30px', sortable: false},
                {text: i18n.user.User, value: 'login', width: '10%'},
                {text: i18n.user.Task, value: 'task', width: '30%', sortable: false},
                {text: i18n.user.Target, value: 'target', width: '20%', sortable: false},
                {text: i18n.user.Message, value: 'message', width: '20%', sortable: false},
                {text: i18n.user.Date, value: 'date', width: '100px'},
            ],
            list: [],
            tariffs: [],
            editedIndex: -1,
            editedItem: {
                name: ''
            },
            defaultItem: {
                name: ''
            },
        }
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? i18n.user.New_item : i18n.user.Edit_item
        },
    },
    created() {
    },
    mounted() {
        var app = this;
        axios.get('/api/v1/logs')
            .then(function (resp) {
                app.list = resp.data;
                app.loading = false;
            })
            .catch(function (resp) {
                app.loading = false;
                alert("Loading error");
            });
    },
    methods: {
        clearAllLogs() {
            var app = this;
            app.overlay = true;
            axios.put('/api/v1/logs/clear')
                .then(function (resp) {
                    app.list = resp.data;
                    app.overlay = false;
                })
                .catch(function (resp) {
                    app.overlay = false;
                });
        },
        getColor(active) {
            return active == 1 ? 'text-success' : 'text-danger';
        },
    }
}
</script>
