<template>
    <div data-app>
        <div class="panel panel-default">
            <div class="panel-body">
                <v-data-table :headers="headers" :items="list" :items-per-page="50" class="evaluate-1"
                              :loading="ap_loading" loading-text="Loading... Please wait">
                    <!-- Dialogs -->
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>{{ i18n.user.Proxy }}</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-dialog v-model="dialogNew" max-width="500px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                        {{ i18n.user.New_item }}
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title>
                                        <span class="headline">{{ formTitle }}</span>
                                    </v-card-title>

                                    <v-card-text>
                                        <v-alert color="#f9c9c9" v-if="hasErrors">
                                            <div v-for="error in validation_errors">
                                                {{ error[0] }}
                                            </div>
                                        </v-alert>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-text-field v-model="editedItem.title"
                                                                  :label="i18n.user.Title"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="5" md="5">
                                                    <v-text-field v-model="editedItem.ip"
                                                                  :label="i18n.user.IP"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="4" md="4">
                                                    <v-text-field v-model="editedItem.port"
                                                                  :label="i18n.user.Port"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="3" md="3">
                                                    <v-checkbox v-model="editedItem.ping"
                                                                :label="i18n.user.Ping"></v-checkbox>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="12">
                                                    <v-text-field v-model="editedItem.password"
                                                                  :label="i18n.user.Password"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-text-field v-model="editedItem.label"
                                                                  :label="i18n.user.Label_only_admins"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-select v-model="editedItem.category" :items="groups"
                                                              item-value="id" item-text="info"
                                                              :label="i18n.user.Category">
                                                    </v-select>
                                                </v-col>

                                            </v-row>
                                        </v-container>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="closeNew">{{
                                                i18n.user.Cancel
                                            }}
                                        </v-btn>
                                        <v-btn lass="btn-primary text-white" text @click="saveNew">{{
                                                i18n.user.Save
                                            }}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <v-dialog v-model="dialog" max-width="500px">
                                <v-card>
                                    <v-card-title>
                                        <span class="headline">{{ formTitle }}</span>
                                    </v-card-title>

                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-text-field v-model="editedItem.title"
                                                                  :label="i18n.user.Title"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="6">
                                                    <v-text-field v-model="editedItem.ip"
                                                                  :label="i18n.user.IP"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="6">
                                                    <v-text-field v-model="editedItem.port"
                                                                  :label="i18n.user.Port"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="12">
                                                    <v-text-field v-model="editedItem.password"
                                                                  :label="i18n.user.Password"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-text-field v-model="editedItem.label" readonly
                                                                  :label="i18n.user.Label_only_admins"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-select v-model="editedItem.category" :items="groups"
                                                              item-value="id" item-text="info"
                                                              :label="i18n.user.Category"></v-select>
                                                </v-col>

                                            </v-row>
                                        </v-container>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" class="mr-1" text @click="close">
                                            {{ i18n.user.Cancel }}
                                        </v-btn>
                                        <v-btn class="btn-primary text-white" text @click="save">{{
                                                i18n.user.Save
                                            }}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>

                        </v-toolbar>
                    </template>
                    <!-- Dialogs -->

                    <template v-slot:body="props">
                        <tbody>
                        <tr v-for="(item, index) in list" :key="item.id">
                            <td class="text-start"> {{ item.user }}</td>
                            <td class="text-start"> {{ item.title }}</td>
                            <td class="text-start"> {{ findGroup(item.category) }}</td>
                            <td class="text-start"> {{ item.ip }}</td>
                            <!--td class="text-start"> {{ item.account }}</td-->
                            <td class="text-start"> {{ item.label }}</td>
                            <td class="text-start"> {{ item.domain }}</td>

                            <td class="text-center"><i class="fas fa-power-off" :class="getColor(item.active)"></i></td>
                            <td class="text-center"> {{ item.error }}</td>
                            <!--td class="text-start"><v-icon small class="mr-2">mdi-lan-connect</v-icon></td-->
                            <td class="text-start">
                                <v-icon v-if="item.ready==0" small class="mr-2 text-primary">mdi-lan-pending</v-icon>
                                <v-icon v-if="item.ready==1" @click="showLog(item)" small class="mr-2 text-success">mdi-lan-connect
                                </v-icon>
                                <v-icon v-if="item.ready>=2" @click="showLog(item)" small class="mr-2 text-danger">mdi-lan-disconnect
                                </v-icon>
                            </td>
                            <td class="text-start">
                                <v-icon small class="mr-2" @click="editItem(item,'active')">mdi-pencil</v-icon>
                                <v-icon small @click="deleteItem(item,'active')">mdi-delete</v-icon>
                            </td>
                        </tr>
                        </tbody>
                    </template>

                    <template slot="headerCell" slot-scope="props">
                        <v-tooltip bottom>
                            <span slot="activator">
                            {{ props.header.text }}
                            </span>
                            <span>
                            {{ props.header.text }}
                            </span>
                        </v-tooltip>
                    </template>
                    <template v-slot:item.active="{ item }">
                        <i class="fas fa-power-off" :class="getColor(item.active)"></i>
                    </template>

                    <template v-slot:item.error="{ item }">
                        {{ item.error }} / 3
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <v-icon small class="mr-2" @click="editItem(item,'active')">mdi-pencil</v-icon>
                        <v-icon small @click="deleteItem(item,'active')">mdi-delete</v-icon>
                    </template>
                    <!--template v-slot:no-data>
                        <v-btn color="primary" @click="initialize">Reset</v-btn>
                    </template-->

                </v-data-table>

                <div class="mt-5">
                    <v-data-table :headers="headers_inactive" :items="list_inactive" :items-per-page="50"
                                  class="evaluate-1" :loading="np_loading" loading-text="Loading... Please wait">
                        <template v-slot:body="props_inactive">
                            <draggable v-model="list_inactive" tag="tbody" @change="sort">
                                <tr v-for="(item, index) in list_inactive" :key="item.id"
                                    :class="item.error==3 ? 'error-row': ''">
                                    <td>
                                        <v-icon small class="page__grab-icon">mdi-arrow-all</v-icon>
                                    </td>
                                    <td class="text-start"> {{ item.title }}</td>
                                    <td class="text-start"> {{ findGroup(item.category) }}</td>
                                    <td class="text-start"> {{ item.ip }}</td>
                                    <td class="text-start"> {{ item.label }}</td>
                                    <td class="text-center"><i class="fas fa-power-off"
                                                               :class="getColor(item.active)"></i></td>
                                    <td class="text-center"> {{ item.error }}</td>
                                    <td class="text-start" nowrap="nowrap">
                                        <v-icon v-if="item.ready==0" small class="mr-2 text-primary">mdi-lan-pending
                                        </v-icon>
                                        <v-icon v-if="item.ready==1" @click="showLog(item)" small class="mr-2 text-success">mdi-lan-connect
                                        </v-icon>
                                        <v-icon v-if="item.ready>=2" @click="showLog(item)" small class="mr-2 text-danger">mdi-lan-disconnect
                                        </v-icon>
                                        <v-icon title="Install soft to proxy" @click="installProxy(item)">mdi-harddisk-plus</v-icon>
                                        <v-icon title="Check install proxy" @click="checkProxy(item)">mdi-bug-check</v-icon>
                                    </td>
                                    <td class="text-start">
                                        <v-icon small class="mr-2" @click="editItem(item,'inactive')">mdi-pencil
                                        </v-icon>
                                        <v-icon small @click="deleteItem(item,'inactive')">mdi-delete</v-icon>
                                    </td>
                                </tr>
                            </draggable>
                        </template>
                        <template slot="headerCell" slot-scope="props">
                            <v-tooltip bottom>
                                <span slot="activator">
                                {{ props.header.text }}
                                </span>
                                <span>
                                {{ props.header.text }}
                                </span>
                            </v-tooltip>
                        </template>
                        <template v-slot:item.active="{ item }">
                            <i class="fas fa-power-off" :class="getColor(item.active)"></i>
                        </template>

                        <template v-slot:item.error="{ item }">
                            {{ item.error }} / 3
                        </template>

                        <template v-slot:item.actions="{ item }">
                            <v-icon small class="mr-2" @click="editItem(item.id,'inactive')">mdi-pencil</v-icon>
                            <v-icon small @click="deleteItem(item,'inactive')">mdi-delete</v-icon>
                        </template>
                        <!--template v-slot:no-data>
                            <v-btn color="primary" @click="initialize">Reset</v-btn>
                        </template-->

                    </v-data-table>
                </div>
            </div>
        </div>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">{{ i18n.user.You_want_remove }}</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" class="mr-1" text @click="closeDelete">{{ i18n.user.Cancel }}</v-btn>
                    <v-btn class="btn-primary text-white" text @click="deleteItemConfirm">
                        {{ i18n.user.ok }}
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogLog" max-width="500px">
            <v-card>
                <v-card-title class="headline">{{ i18n.user.Show_log }}</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <pre class="overflow-x-auto" v-html="log" style="height: 600px;">
                    </pre>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-overlay :value="overlay" z-index="2000">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            dialog: false,
            dialogNew: false,
            dialogDelete: false,
            dialogLog: false,
            log: '',
            ap_loading: true,
            np_loading: true,
            usedListing: 'active',
            validation_errors: [],
            hasErrors: false,
            overlay: false,
            headers: [
                {text: i18n.user.User, value: 'user', width: '20%'},
                {text: i18n.user.Title, value: 'title', width: '30%'},
                {text: i18n.user.Group, value: 'category', width: '10%'},
                {text: i18n.user.IP, sortable: false, value: 'ip', width: '1%'},
                /*{ text: i18n.user.Account, sortable: false, value: 'account', width:'1%' },*/
                {text: i18n.user.Label, sortable: false, value: 'label', width: '1%'},
                {text: i18n.user.Sites, sortable: false, value: 'domain', width: '1%'},
                {text: i18n.user.Active, value: 'active', width: '130px', align: 'center'},
                {text: i18n.user.Error, value: 'error', width: '130px', align: 'center'},
                {text: "#", sortable: false, value: 'ready', width: '1%'},
                {text: i18n.user.Actions, sortable: false, value: 'actions', width: '5%'}
            ],
            headers_inactive: [
                {text: '#', sortable: false, value: 'id', width: '1%'},
                {text: i18n.user.Title, value: 'title', width: '43%'},
                {text: i18n.user.Group, value: 'category', width: '10%'},
                {text: i18n.user.IP, sortable: false, value: 'ip', width: '1%'},
                {text: i18n.user.Label, sortable: false, value: 'label', width: '1%'},
                {text: i18n.user.Active, value: 'active', width: '130px', align: 'center'},
                {text: i18n.user.Error, value: 'error', width: '130px', align: 'center'},
                {text: "#", sortable: false, value: 'ready', width: '5%'},
                {text: i18n.user.Actions, sortable: false, value: 'actions', width: '5%'}
            ],
            list: [],
            list_inactive: [],
            groups: [],
            newRawData: '',
            editedIndex: -1,
            editedItem: {
                title: '',
                ip: '',
                port: '',
                label: '',
                ping: '',
                password: '',
                active: 0,
                ready: 0,
                user_id: 0,
            },
            defaultItem: {
                title: '',
                ip: '',
                port: '',
                label: '',
                ping: '',
                password: '',
                active: 0,
                ready: 0,
                user_id: 0,
            },
        }
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? i18n.user.New_item : i18n.user.Edit_item
        },
    },
    created() {
        this.getGroups();
    },
    mounted() {
        var app = this;
        axios.get('/api/v1/users_proxy')
            .then(function (resp) {
                app.list = resp.data;
                app.ap_loading = false;
            })
            .catch(function (resp) {
                app.ap_loading = false;
                alert("Loading error");
            });

        axios.get('/api/v1/users_proxy_inactive')
            .then(function (resp) {
                app.np_loading = false;
                app.list_inactive = resp.data;
            })
            .catch(function (resp) {
                app.np_loading = false;
                alert("Loading error");
            });
    },
    methods: {
        sort: function (evt) {
            let sort = [];
            for (let i in this.list_inactive) {
                sort[i] = this.list_inactive[i].id;
            }
            axios.post('/api/v1/users_proxy/sort', {'ids': sort})
                .then(function (resp) {
                })
                .catch(function (resp) {
                    alert("Sorting error");
                });

        },
        findGroup: function (i) {
            for (let g in this.groups) {
                if (this.groups[g].id == i) {
                    return this.groups[g].name;
                }
            }
            return '';
        },
        getGroups: function () {
            let app = this;
            axios.get('/api/v1/proxy_group')
                .then((response) => {
                    app.groups = [{
                        id: 0,
                        name: i18n.user.Any_element,
                        info: i18n.user.Any_element
                    }].concat(response.data);
                    console.log(app.groups);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        editItem(item, listName) {
            this.usedListing = listName;
            console.log(listName);
            if (listName === 'active') {
                this.editedIndex = this.list.indexOf(item);
            } else {
                console.log('Inactive find');
                this.editedIndex = this.list_inactive.indexOf(item);
            }
            this.editedItem = Object.assign({}, item);
            console.log(this.list_inactive);
            //console.log(this.editedItem);
            this.dialog = true
        },
        installProxy(item) {
            this.overlay = true;
            let app = this;
            this.editedIndex = this.list_inactive.indexOf(item);
            this.editedItem = Object.assign({}, item);
            console.log(this.list_inactive);
            axios.get('/api/v1/proxy_install/' + app.editedItem.id)
                .then((response) => {
                    app.overlay = false;
                    app.list_inactive[this.editedIndex]['ready'] = response.data['ready']
                    app.list_inactive[this.editedIndex]['log_install'] = response.data['log_install']
                })
                .catch(function (error) {
                    console.log(error);
                    app.overlay = false;
                });
        },
        checkProxy(item) {
            this.overlay = true;
            let app = this;
            this.editedIndex = this.list_inactive.indexOf(item);
            this.editedItem = Object.assign({}, item);
            console.log(this.list_inactive);
            axios.get('/api/v1/proxy_check_install/' + app.editedItem.id)
                .then((response) => {
                    app.overlay = false;
                    app.list_inactive[this.editedIndex]['ready'] = response.data['ready'];
                    console.log(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                    app.overlay = false;
                });
        },
        showLog(item) {
            this.dialogLog = true;
            this.log = item.log_install.replace("\n","<br>");
        },
        deleteItem(item, listName) {
            this.usedListing = listName;
            if (listName === 'active') {
                this.editedIndex = this.list.indexOf(item);
            } else {
                this.editedIndex = this.list_inactive.indexOf(item);
            }
            console.log(this.editedIndex);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            if (this.usedListing === 'active') {
                this.list.splice(this.editedIndex, 1);
            } else {
                this.list_inactive.splice(this.editedIndex, 1);
            }
            var app = this;
            axios.delete('/api/v1/users_proxy/' + app.editedItem.id)
                .then(function (resp) {
                })
                .catch(function (resp) {
                    console.log(resp);
                    alert("Remove item error");
                });
            this.closeDelete()
        },

        getColor(active) {
            return active == 1 ? 'text-success' : 'text-danger';
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeNew() {
            this.dialogNew = false
            this.$nextTick(() => {
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        save() {
            if (this.editedIndex > -1) {

                let app = this;
                axios.put('/api/v1/users_proxy/' + app.editedItem.id, {
                    id: app.editedItem.id,
                    title: app.editedItem.title,
                    password: app.editedItem.password,
                    category: app.editedItem.category,
                    ip: app.editedItem.ip,
                    port: app.editedItem.port,
                    label: app.editedItem.label
                })
                    .then(function (resp) {
                        app.close();

                    })
                    .catch(function (resp) {
                        app.close();
                        console.log(resp);
                        alert("Update item error");
                    });

            } else {
                this.list.push(this.editedItem)
            }
            if (this.editedItem.active) {
                Object.assign(this.list[this.editedIndex], this.editedItem)
            } else {
                Object.assign(this.list_inactive[this.editedIndex], this.editedItem)
            }
        },
        saveNew() {
            this.overlay = true;
            let app = this;
            app.validation_errors = [];
            axios.post('/api/v1/users_proxy/create', {
                id: app.editedItem.id,
                title: app.editedItem.title,
                password: app.editedItem.password,
                category: app.editedItem.category,
                ip: app.editedItem.ip,
                port: app.editedItem.port,
                label: app.editedItem.label,
                ping: app.editedItem.ping
            })
                .then(function (resp) {
                    app.list_inactive = resp.data
                    app.validation_errors = [];
                    app.hasErrors = false;
                    app.editedItem = app.defaultItem;
                    app.closeNew()
                    app.overlay = false;
                })
                .catch(function (resp) {
                    app.validation_errors = resp.response.data.errors;
                    app.hasErrors = true;
                    console.log(app.validation_errors);
                    app.overlay = false;
                });
        },
    }
}
</script>
