<template>
    <div data-app>
        <div class="panel panel-default">
            <div class="panel-body">
                <div class="alert alert-success">{{ __("user.Sites_guide") }}</div>
                <div class="row">
                    <div class="col-md-7">
                        <v-data-table :headers="headers" :items="list" :items-per-page="50" item-key="id"
                                      class="elevation-1" :loading="loading" show-select v-model="selected"
                                      :loading-text=i18n.user.Empty @click:row="editItem"  no-data-text="Empty">
                            <template slot="headerCell" slot-scope="props">
                                <v-tooltip bottom>
                                    <span slot="activator">
                                    {{ props.header.text }}
                                    </span>
                                    <span>
                                    {{ props.header.text }}
                                    </span>
                                </v-tooltip>
                            </template>
                            <!--template v-slot:item.active="{ item }">
                                <i class="fas fa-power-off" :class="getColor(item.active)"></i>
                            </template-->
                            <template v-slot:item.domain="{ item }">
                                {{ item.domain }} <v-icon small class="red" @click.stop="copySite(item.domain)">mdi-content-copy</v-icon>
                            </template>
                            <template v-slot:item.id="{ item }">
                                {{ list.map(function(x) {return x.id; }).indexOf(item.id)+1 }}
                            </template>
                            <template v-slot:item.deadline="{ item }">
                                {{ item.deadline | formatDate }}
                            </template>

                            <template v-slot:item.date="{ item }">
                                {{ item.date | formatDate }}
                            </template>

                            <template v-slot:item.actions="{ item }">
                                <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
                                <v-icon small @click="deleteItem(item)" v-if="item.used_count==0">mdi-delete</v-icon>
                            </template>
                            <!--template v-slot:no-data>
                                <v-btn color="primary" @click="initialize">Reset</v-btn>
                            </template-->

                        </v-data-table>
                        <v-btn class="btn-danger text-white" text @click="deleteGroup">{{ i18n.user.Delete }}</v-btn>
                    </div>
                    <div class="col-md-5">
                            <v-card>
                                <v-card-title>
                                    <span class="headline">{{ formTitle }}</span>
                                </v-card-title>

                                <v-card-text>
                                    <v-alert color="#f9c9c9" v-if="hasErrors">
                                        <div v-for="error in validation_errors">
                                            {{ error[0] }}
                                        </div>
                                    </v-alert>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" sm="12" md="6">
                                                <v-text-field v-model="editedItem.domain" :label="i18n.user.Domain"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="6">
                                                <v-text-field v-model="editedItem.ip" :label="i18n.user.IP"></v-text-field>
                                            </v-col>
                                            <v-col cols="6" sm="12" md="3">
                                                <v-combobox multiple v-model="editedItem.port" :label=i18n.user.Inp_Port append-icon></v-combobox>
                                            </v-col>
                                            <v-col cols="6" sm="12" md="3">
                                                <v-combobox multiple v-model="editedItem.port_out" :label=i18n.user.Out_Port append-icon></v-combobox>
                                            </v-col>
                                            <v-col cols="6" sm="12" md="6" v-if="proxy_type.proxy_type=='haproxy'">
                                                <v-checkbox  v-model="editedItem.public_ip" :label="i18n.user.https_send_proxy" class="d-inline-block"></v-checkbox>
                                            </v-col>
                                            <!--v-col cols="12" sm="12" md="12">
                                                <v-select v-model="editedItem.dns" :items="dns_list" :label="i18n.user.DNS" attach></v-select>
                                            </v-col-->
                                        </v-row>
                                    </v-container>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="saveNew">{{ i18n.user.Save }}</v-btn>
                                </v-card-actions>
                            </v-card>
                    </div>
                </div>
            </div>
        </div>
        <v-overlay :value="overlay" z-index="2000">
            <v-progress-circular indeterminate size="64" ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                dialog: false,
                dialogNew: false,
                dialogDelete: false,
                loading: true,
                selected: [],
                proxy_type: {proxy_type: 'haproxy'},
                validation_errors: [],
                hasErrors: false,
                overlay: false,
                inviteLink: '',
                headers: [
                    { text: '#', align: 'start', value: 'id', width: '30px', sortable: false },
                    { text: i18n.user.Domain, value: 'domain', width: '24%', sortable: false },
                    { text: i18n.user.IP, value: 'ip', width: '24%', sortable: false },
                    { text: i18n.user.DNS, value: 'dns', width: '24%', sortable: false },
                    { text: i18n.user.Date, value: 'date', width: '24%', sortable: false },
                ],
                list: [],
                dns_list:['dnspod','custom'],
                ports: [],
                editedIndex: -1,
                editedItem: {
                    port: ['80'],
                    port_out: ['80'],
                    dns: 'dnspod',
                },
                defaultItem: {
                    port: ['80'],
                    port_out: ['80'],
                    dns: 'dnspod',
                },
            }
        },
        computed: {
            formTitle () {
                return this.editedIndex === -1 ? i18n.user.New_item : i18n.user.Edit_item
            },
        },
        created() {
        },
        mounted() {
            var app = this;
            axios.get('/api/v1/account/settings')
                .then(function (resp) {
                    app.proxy_type = resp.data;
                })
                .catch(function (resp) {});
            axios.get('/api/v1/account/sites')
                .then(function (resp) {
                    app.loading = false;
                    app.list = resp.data;
                })
                .catch(function (resp) {
                    alert("Loading error");
                });
        },
        methods: {
            copySite (value) {
                this.$clipboard(value);
            },
            editItem (item) {
                this.$router.push({ name: 'account.sitesEdit',params: {id: item.id}});
            },
            deleteGroup() {
                this.overlay = true;
                let app = this;
                let ids = [];
                this.loading = true;
                for (let e in this.selected) {
                    ids.push(this.selected[e].id);
                }
                console.log(ids);
                axios.post('/api/v1/account/sites/deleteGroup', {
                    ids: ids,
                })
                    .then(function (resp) {
                        app.loading = false;
                        console.log(resp.data);
                        app.list = resp.data
                        app.validation_errors = [];
                        app.hasErrors = false;
                        app.overlay = false;
                        app.editedItem = app.defaultItem;
                        app.overlay = false;
                    })
                    .catch(function (resp) {
                        app.loading = false;
                        app.validation_errors = resp.response.data.errors;
                        app.hasErrors = true;
                        app.overlay = false;
                        console.log(app.validation_errors);
                    });
            },
            saveNew () {
                this.overlay = true;
                let app = this;
                axios.post('/api/v1/account/sites/create', {
                    id: app.editedItem.id,
                    domain: app.editedItem.domain,
                    ip: app.editedItem.ip,
                    public_ip: app.editedItem.public_ip,
                    //dns: app.editedItem.dns,
                    dns: 'dnspod',
                    port: app.editedItem.port.join(','),
                    port_out: app.editedItem.port_out.join(','),
                })
                .then(function (resp) {
                    console.log(resp.data);
                    app.list = resp.data
                    app.validation_errors = [];
                    app.hasErrors = false;
                    app.overlay = false;
                    app.editedItem = app.defaultItem;
                    app.overlay = false;
                })
                .catch(function (resp) {
                    app.validation_errors = resp.response.data.errors;
                    app.hasErrors = true;
                    app.overlay = false;
                    console.log(app.validation_errors);
                    //alert("Update item error");
                });
            },
        }
    }
</script>
