<template>
    <div data-app>
        <v-row>
            <v-col cols="12" md="6">
                <div class="card">
                    <div class="card-header">{{ i18n.user.Reg_balance }}</div>
                    <div class="card-body">
                        {{ amount }} USD
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                amount: 0,
            }
        },
        mounted() {
            var app = this;
            axios.get('/api/v1/reg/balance')
                .then(function (resp) {
                    console.log(resp.data);
                    app.amount = resp.data.answer.prepay;
                })
                .catch(function (resp) {
                    console.error("Loading error");
                });
        }
    }
</script>
