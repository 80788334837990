<template>
    <div data-app>
        <div class="panel panel-default">
            <div class="panel-body">
                <v-data-table :headers="headers" :items="list" hide-default-footer items-per-page="1000" class="evaluate-1" :loading="loading" loading-text="Loading... Please wait">
                    <!-- Dialogs -->
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>{{ i18n.user.Reg_prices }}</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                    </template>
                    <!-- Dialogs -->
                    <template slot="headerCell" slot-scope="props">
                        <v-tooltip bottom>
                            <span slot="activator">
                            {{ props.header.text }}
                            </span>
                            <span>
                            {{ props.header.text }}
                            </span>
                        </v-tooltip>
                    </template>

                    <template v-slot:body.prepend="{ headers }">
                        <tr>
                            <td colspan="2" class="d-none d-md-table-cell">&nbsp;</td>
                            <td class="d-none d-md-table-cell">{{ i18n.user.All }}</td>
                            <td><v-text-field v-model="margin.total" :label="i18n.user.Margin"></v-text-field></td>
                            <td>
                                <v-icon small class="mr-2" @click="setMargin()" :title="i18n.user.Set_to_all_zones">mdi-gesture-tap-button</v-icon>
                                <v-icon small class="mr-2" @click="saveMargins()" :title="i18n.user.Save_price">mdi-content-save</v-icon>
                            </td>
                        </tr>
                    </template>

                    <template v-slot:item.date="{ item }">
                        {{ item.date | formatDate }}
                    </template>
                    <template v-slot:item.increase="{ item }">
                        <div @click="showMarginText(item)">
                        <v-text-field v-model="item.increase" v-if="item.edit_margin"></v-text-field>
                        <span v-if="!item.edit_margin">{{ item.increase }}</span>
                        </div>
                    </template>

                </v-data-table>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                dialog: false,
                dialogNew: false,
                loading: true,
                dialogDelete: false,
                edit_margin: [],
                margin: {
                    total: 10,
                },
                headers: [
                    { text: '#', sortable: false, value: 'id', width: '1%' },
                    { text: i18n.user.Zone, sortable: false, value: 'zone', width: '60%' },
                    { text: i18n.user.Price, sortable: false, value: 'price', width: '15%' },
                    { text: i18n.user.Margin, sortable: false, value: 'increase', width: '15%' },
                    { text: i18n.user.Date, sortable: false, value: 'date', width: '10%' },
                ],
                list: [],
                users: [],
                editedIndex: -1,
                editedItem: {
                    zone: '',
                    price: '',
                    increase: '',
                },
                defaultItem: {
                    zone: '',
                    price: '',
                    increase: '',
                },
            }
        },
        computed: {
            formTitle () {
                return this.editedIndex === -1 ? i18n.user.New_item : i18n.user.Edit_item
            },
        },
        created() {
        },
        mounted() {
            var app = this;
            axios.get('/api/v1/domain/reg_price')
                .then(function (resp) {
                    app.list = resp.data;
                    app.loading = false;
                    for (let i in app.list) {
                        app.list[i].edit_margin = false;
                    }
                })
                .catch(function (resp) {
                    app.loading = false;
                    alert("Loading error");
                });
        },
        methods: {
            showMarginText(item) {
                console.log(item);
                item.increase = parseFloat(item.increase)+0.00001;
                item.increase -= 0.00001;
                item.edit_margin = true;
            },
            setMargin() {
                let app = this;
                this.list.map(item => {item.increase=app.margin.total; item.edit_margin = false;});
            },
            getColor(active) {
                return active===1 ? 'text-success' : 'text-danger';
            },

            saveMargins () {
                let app = this;
                console.log("ID:::" + this.editedItem.user);
                let margins = [];
                for (let i in app.list) {
                    //if (app.list[i].edit_margin) {
                        margins.push({id: app.list[i].id, margin: parseFloat(app.list[i].increase)});
                    //}
                }
                axios.post('/api/v1/domain/reg_price/save_margins',margins)
                    .then(function (resp) {
                        app.list = resp.data;
                    })
                    .catch(function (resp) {
                        console.log(resp);
                        alert("Update item error");
                    });

            },
        }
    }
</script>
