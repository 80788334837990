<template>
    <div data-app>
        <div class="panel panel-default">
            <div class="panel-body">
                <div class="row">
                    <div class="col-md-7">
                        <v-data-table :headers="headers" :items="list" :items-per-page="itemsPerPage" class="elevation-1" :loading="loading"
                                      :loading-text=i18n.user.Empty @click:row="editItem" no-data-text="Empty">
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <v-toolbar-title>{{ i18n.user.News}}</v-toolbar-title>
                                    <v-divider class="mx-4" inset vertical></v-divider>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" dark class="mb-2" @click="createNew">{{ i18n.user.New_item}}</v-btn>

                                </v-toolbar>
                            </template>

                            <template slot="headerCell" slot-scope="props">
                                <v-tooltip bottom>
                                    <span slot="activator">
                                    {{ props.header.text }}
                                    </span>
                                    <span>
                                    {{ props.header.text }}
                                    </span>
                                </v-tooltip>
                            </template>

                            <template v-slot:item.date="{ item }">
                                {{ item.date | formatDate }}
                            </template>

                            <template v-slot:item.text="{ item }">
                                <div class="text-xs-truncate">{{ item.text }}</div>
                            </template>

                            <template v-slot:no-data>
                                <v-btn color="primary" @click="initialize">Reset</v-btn>
                            </template>
                            <!--template v-slot:item.status="{ item }">
                                <span v-if="item.status===1" class="fas fa-check text-success"></span><span v-else class="fas fa-times text-danger"></span>
                            </template-->

                        </v-data-table>
                    </div>
                    <div class="col-md-5">
                        <v-card>
                            <v-card-title>
                                <span class="headline">{{ formTitle }}</span>
                            </v-card-title>

                            <v-card-text>
                                <v-alert color="#f9c9c9" v-if="hasErrors">
                                    <div v-for="error in validation_errors">
                                        {{ error[0] }}
                                    </div>
                                </v-alert>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-text-field v-model="editedItem.title_ru"
                                                          :label="i18n.user.Title+' (RU)'"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-text-field v-model="editedItem.text_ru" :label="i18n.user.Text+' (RU)'"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-text-field v-model="editedItem.title_en"
                                                          :label="i18n.user.Title+' (EN)'"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-text-field v-model="editedItem.text_en" :label="i18n.user.Text+' (EN)'"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="6">
                                            <v-checkbox v-model="editedItem.status" :label=i18n.user.Activated append-icon></v-checkbox>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="6">
                                            <!--v-text-field v-model="editedItem.date" :label="i18n.user.Date" type="datetime-local"></v-text-field-->
                                            <div class="v-input theme--light v-text-field v-text-field--is-booted">
                                                <div class="v-input__control">
                                                    <div class="v-input__slot">
                                                        <div class="v-text-field__slot">
                                                            <label for="input-pk" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">
                                                                {{ i18n.user.Date }}</label>
                                                            <datepicker format="dd.MM.yyyy" id="input-pk" v-model="editedItem.date"></datepicker>
                                                        </div>
                                                    </div>
                                                    <div class="v-text-field__details">
                                                        <div class="v-messages theme--light">
                                                            <div class="v-messages__wrapper"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <div v-if="new_record">
                                <v-btn color="blue darken-1" text @click="saveNew">{{ i18n.user.Save }}</v-btn>
                                </div>
                                <div v-if="!new_record">
                                    <v-btn  color="red darken-1" text @click="deleteNew">{{ i18n.user.Delete }}</v-btn>
                                    <v-btn color="primary" text @click="saveModified">{{ i18n.user.Save }}</v-btn>
                                </div>
                            </v-card-actions>
                        </v-card>
                    </div>
                </div>
            </div>
        </div>
        <v-overlay :value="overlay" z-index="2000">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            dialog: false,
            dialogNew: false,
            dialogDelete: false,
            new_record: true,
            validation_errors: [],
            itemsPerPage: 50,
            loading: true,
            hasErrors: false,
            overlay: false,
            headers: [
                {text: '#', align: 'start', value: 'id', width: '30px', sortable: false},
                {text: i18n.user.Title, value: 'title', width: '20%', sortable: false},
                {text: i18n.user.Text, value: 'text', width: '20%', sortable: false},
                {text: i18n.user.User, value: 'user', width: '20%', sortable: false},
                {text: i18n.user.Status, value: 'status', width: '10%', sortable: false},
                {text: i18n.user.Date, value: 'date', width: '20%', sortable: false},
            ],
            list: [],
            editedIndex: -1,
            editedItem: {
                title_ru:'',
                text_ru:'',
                title_en:'',
                text_en:'',
                status: 0,
                pack: 0,
            },
            defaultItem: {
                title_ru:'',
                text_ru:'',
                title_en:'',
                text_en:'',
                status: 0,
                pack: 0,
                date: new Date(),
            },
        }
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? i18n.user.New_item : i18n.user.Edit_item
        },
    },
    created() {
    },
    mounted() {
        this.editedItem = this.defaultItem;
        var app = this;
        axios.get('/api/v1/news?lang='+window.app.lang)
            .then(function (resp) {
                app.list = resp.data;
                app.loading = false;
            })
            .catch(function (resp) {
                app.loading = false;
                alert("Loading error");
            });
    },
    methods: {
        createNew() {
            this.editedItem = this.defaultItem;
            this.new_record = true;
        },
        editItem(item) {
            let app = this;
            this.new_record = false;
            //this.$router.push({name: 'account.sitesEdit', params: {id: item.id}});
            axios.get('/api/v1/news/' + item.id+'/edit?lang='+window.app.lang)
                .then(function (resp) {
                    app.edit = true;
                    app.editedItem = resp.data;
                    app.editedItem.date=app.editedItem.date.replace(' ','T');
                    app.loading = false;
                })
                .catch(function () {
                    app.loading = false;
                    alert("Не удалось загрузить")
                });
        },
        saveNew() {
            this.overlay = true;
            let app = this;
            app.overlay = true;
            axios.post('/api/v1/news/create', {
                id: app.editedItem.id,
                title_ru: app.editedItem.title_ru,
                title_en: app.editedItem.title_en,
                text_ru: app.editedItem.text_ru,
                text_en: app.editedItem.text_en,
                status: app.editedItem.status,
                date: app.editedItem.date,
            })
                .then(function (resp) {
                    console.log(resp.data);
                    app.list = resp.data
                    app.validation_errors = [];
                    app.hasErrors = false;
                    app.overlay = false;
                    app.editedItem = app.defaultItem;
                    app.overlay = false;
                    app.createNew();
                })
                .catch(function (resp) {
                    app.validation_errors = resp.response.data.errors;
                    app.hasErrors = true;
                    app.overlay = false;
                    console.log(app.validation_errors);
                    //alert("Update item error");
                });
        },
        saveModified () {
            let app = this;
            this.overlay = true;
            axios.put('/api/v1/news/' + app.editedItem.id_ru+'?lang='+window.app.lang,{
                id_ru: app.editedItem.id_ru,
                id_en: app.editedItem.id_en,
                title_ru: app.editedItem.title_ru,
                title_en: app.editedItem.title_en,
                text_ru: app.editedItem.text_ru,
                text_en: app.editedItem.text_en,
                status: app.editedItem.status,
                date: app.editedItem.date,
            })
                .then(function (resp) {
                    app.list = resp.data
                    app.overlay = false;
                    app.createNew();
                })
                .catch(function (resp) {
                    console.log(resp);
                    app.overlay = false;
                    alert("Update item error");
                });
        },
        deleteNew () {
            let app = this;
            this.overlay = true;
            axios.delete('/api/v1/news/' + app.editedItem.id_ru+'?lang='+window.app.lang+'&id_en='+app.editedItem.id_en,{
                id_ru: app.editedItem.id_ru,
                id_en: app.editedItem.id_en,
            })
                .then(function (resp) {
                    app.list = resp.data
                    app.overlay = false;
                    app.createNew();
                })
                .catch(function (resp) {
                    console.log(resp);
                    app.createNew();
                    app.overlay = false;
                    alert("Removing item error");
                });
        },
    }
}
</script>
