<template>
    <div data-app>
        <div class="panel panel-default">
            <div class="panel-body">
                <v-card class="mb-3">
                    <v-card-title>
                        <span class="headline">{{ i18n.user.Settings }}</span>
                    </v-card-title>

                    <v-card-text>
                        <v-alert color="#f9c9c9" v-if="hasErrors">
                            <div v-for="error in validation_errors">
                                {{ error[0] }}
                            </div>
                        </v-alert>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <v-select v-model="editedItem.proxy_type" :items="proxy_list"
                                              :label="i18n.user.Proxy" attach></v-select>
                                    {{ i18n.user.Proxy_modify_alert }}
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="save">{{ i18n.user.Save }}</v-btn>
                    </v-card-actions>

                </v-card>
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ i18n.user.Password_change }}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-alert color="#f9c9c9" v-if="hasErrorsPassword">
                            <div v-for="error in validation_errors">
                                {{ error[0] }}
                            </div>
                        </v-alert>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field v-model="editedItem.password" :label="password_placeholder"
                                                  attach></v-text-field>
                                    {{ i18n.user.Password_modify_alert }}
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="save_password">{{ i18n.user.Save }}</v-btn>
                    </v-card-actions>
                </v-card>
            </div>
        </div>
        <v-overlay :value="overlay" z-index="2000">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            dialog: false,
            validation_errors: [],
            password_placeholder: i18n.user.Password,
            hasErrors: false,
            hasErrorsPassword: false,
            overlay: false,
            proxy_list: ['haproxy', 'nginx'],
            editedItem: {
                proxy_type: 'haproxy',
                password: '',
            }
        }
    },
    computed: {},
    created() {
    },
    mounted() {
        var app = this;
        axios.get('/api/v1/account/settings')
            .then(function (resp) {
                app.editedItem = resp.data;
                app.editedItem.password = '';
            })
            .catch(function (resp) {
                alert("Loading error");
            });
    },
    methods: {
        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        save() {
            let app = this;
            this.overlay = true;
            axios.put('/api/v1/account/settings/update/', {
                id: app.editedItem.id,
                proxy_type: app.editedItem.proxy_type,
            })
            .then(function (resp) {
                app.overlay = false;
            })
            .catch(function (resp) {
                console.log(resp);
                app.overlay = false;
                alert("Update item error");
            });
        },
        save_password() {
            let app = this;
            this.overlay = true;
            axios.put('/api/v1/account/' + app.editedItem.id + '/password/update/', {
                id: app.editedItem.id,
                password: app.editedItem.password,
            })
                .then(function (resp) {
                    app.overlay = false;
                    app.validation_errors = [];
                    app.overlay = false;
                    app.hasErrorsPassword = false;
                    app.editedItem.password = '';
                    app.password_placeholder = i18n.user.Password_modified;
                })
                .catch(function (resp) {
                    app.overlay = false;
                    app.validation_errors = resp.response.data.errors;
                    app.hasErrorsPassword = true;
                    app.overlay = false;
                    //alert("Update item error");
                });
        },
    }
}
</script>
