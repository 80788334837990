<template>
    <div data-app>
        <div class="panel panel-default">
            <div class="panel-body">
                <v-data-table :headers="headers" :items="list" :items-per-page="50" class="evaluate-1"
                              :loading="loading"
                              loading-text="Loading... Please wait">
                    <!-- Dialogs -->
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>{{ i18n.user.Statistic }}</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <div class=" float-right">
                                <div class="row">
                                    <div class="col-3">
                                        <v-btn color="primary" @click="setDate(-1, true)">&lt;</v-btn>
                                    </div>
                                    <div class="col-6 text-center display-5">{{ date }}</div>
                                    <div class="col-3">
                                        <v-btn color="primary" @click="setDate(1, true)">&gt;</v-btn>
                                    </div>
                                </div>
                            </div>
                        </v-toolbar>
                    </template>
                    <!-- Dialogs -->

                    <template v-slot:item.date="{ item }">
                        {{ item.date | formatDateTime }}
                    </template>

                    <template v-slot:item.amount="{ item }">
                        {{ item.amount < 0 ? item.amount * -1 : item.amount }}
                    </template>

                    <template v-slot:item.profit="{ item }">
                        <div v-if="item.type == 2">
                            <!--{% set total = total + item.count * 5 %}-->
                            {{ item.count * 5 }} $
                        </div>
                        <div v-if="item.type in [6, 7, 8]">
                            <!--{% set total = total + item.count * 10 %}-->
                            {{ item.count * 10 }} $
                        </div>
                    </template>

                    <template v-slot:no-data>
                        <v-btn color="primary" @click="loadData(0,0)">Reset</v-btn>
                    </template>

                </v-data-table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data: function () {
        return {
            host: location.host,
            inviteLink: '',
            date: this.setDate(),
            month: 0,
            year: 0,
            offset: 'now',
            loading: true,
            headers: [
                {text: i18n.user.Target, value: 'target', width: '30%'},
                {text: i18n.user.Success_ops, value: 'count', width: '20%', sortable: false},
                {text: i18n.user.Turnover, value: 'amount', width: '25%', sortable: false},
                {text: i18n.user.Profit, value: 'profit', width: '25%', sortable: false},
            ],
            list: [],
            tariffs: [],
        }
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? i18n.user.New_item : i18n.user.Edit_item
        },
    },
    created() {
    },
    mounted() {
        let m = new Date();
        this.month = m.getUTCMonth() + 1;
        this.year = m.getUTCFullYear();
        this.loadData(this.month, this.year);
    },
    methods: {
        loadData(month, year) {
            if (month == 0) {
                month = this.month;
                year = this.year;
            }
            var app = this;
            axios.get('/api/v1/stats/' + month + '/' + year)
                .then(function (resp) {
                    app.list = resp.data;
                    app.loading = false;
                })
                .catch(function (resp) {
                    app.loading = false;
                    alert("Loading error");
                });
        },
        setDate(direction = 0, load_data = false) {
            let month = this.month;
            if (direction === 0) {
                let m = new Date();
                this.month = m.getUTCMonth() + 1;
                this.year = m.getUTCFullYear();
            }
            if (direction === 1) {
                if (this.month > 11) {
                    this.month = 1;
                    this.year += 1;
                } else {
                    this.month += 1
                }
            }
            if (direction === -1) {
                if (this.month === 1) {
                    this.month = 12;
                    this.year -= 1;
                } else {
                    this.month -= 1;
                }
            }
            if (this.month < 10) {
                month = '0' + this.month;
            }
            this.date = (this.month<10 ? '0' : '')+this.month + '.' + this.year;

            if (load_data) {
                this.loadData(this.month,this.year);
            }

            return this.date;
        },
        getColor(active) {
            return active == 1 ? 'text-success' : 'text-danger';
        },
    }
}
</script>
