/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

// Inculde FontAwesome Icons
import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'
import draggable from 'vuedraggable'

//import Datepicker from 'vuejs-datepicker';

import Vue from 'vue'
import VueRouter from 'vue-router';
import Vuetify from 'vuetify';

Vue.component('draggable', draggable);

import moment from 'moment';
import Clipboard from 'v-clipboard';
import DatetimePicker from 'vuetify-datetime-picker'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

Vue.prototype.i18n = window.i18n;
window.moment = moment;

import axios from 'axios';
Vue.use('axios')
Vue.use(Clipboard)
Vue.use(Vuetify)
Vue.use(DatetimePicker)
Vue.use(moment)
//Vue.component("datepicker",Datepicker)


Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format('DD-MM-YY')
    }
});

Vue.filter('formatDateTime', function(value) {
    if (value) {
        return moment(String(value)).format('YY-MM-DD HH:mm:ss')
    }
});

const routes = [
    {path: '/:lang?/admin', component: require('./components/AdminIndex.vue').default, name: 'adminIndex'},
    {path: '/:lang?/admin/news', component: require('./components/news/newsIndex.vue').default, name: 'newsIndex'},
    {path: '/:lang?/admin/logs', component: require('./components/logs/logsIndex.vue').default, name: 'logsIndex'},
    {path: '/:lang?/admin/stats', component: require('./components/logs/statsIndex.vue').default, name: 'statsIndex'},
    {path: '/:lang?/admin/transactions', component: require('./components/transactions/transactionsIndex.vue').default, name: 'transactionsIndex'},
    {path: '/:lang?/admin/users', component: require('./components/users/usersIndex.vue').default, name: 'usersIndex'},
    {path: '/:lang?/admin/users/:id/edit', component: require('./components/users/usersEdit.vue').default, name: 'usersEdit'},
    {path: '/:lang?/admin/dnspod', component: require('./components/dnspod/dnspodIndex.vue').default, name: 'DNSPODIndex'},
    {path: '/:lang?/admin/users_proxy', component: require('./components/users_proxy/UsersProxyIndex.vue').default, name: 'UsersProxyIndex'},
    {path: '/:lang?/admin/proxy_group', component: require('./components/users_proxy/ProxyGroupIndex.vue').default, name: 'ProxyGroupIndex'},
    {path: '/:lang?/admin/vps_tariff', component: require('./components/vps/VPSTariffIndex.vue').default, name: 'VPSTariffIndex'},
    {path: '/:lang?/admin/tariff', component: require('./components/tariff/TariffIndex.vue').default, name: 'TariffIndex'},
    {path: '/:lang?/admin/domain/reg_price', component: require('./components/domains/RegPriceIndex.vue').default, name: 'RegPriceIndex'},
    {path: '/:lang?/admin/domain/webnic_price', component: require('./components/domains/WebnicPriceIndex.vue').default, name: 'WebnicPriceIndex'},
    {path: '/:lang?/admin/users/:user_id/site/:id/edit', component: require('./components/users/SitesEdit.vue').default, name: 'UserSiteEdit'},
    // Account routes
    {path: '/:lang?/account/api', component: require('./components/account/APIIndex.vue').default, name: 'account.API'},
    {path: '/:lang?/account/settings', component: require('./components/account/SettingsIndex.vue').default, name: 'account.Settings'},
    {path: '/:lang?/account/news', component: require('./components/account/NewsIndex.vue').default, name: 'account.News'},
    {path: '/:lang?/account/ssl_keys', component: require('./components/account/SSLKeysIndex.vue').default, name: 'account.SSL_keys'},
    {path: '/:lang?/account/sites', component: require('./components/account/SitesIndex.vue').default, name: 'account.Sites'},
    {path: '/:lang?/account/sites/:id/edit', component: require('./components/account/SitesEdit.vue').default, name: 'account.sitesEdit'},
    {path: '/:lang?/account/domains', component: require('./components/account/DomainsIndex.vue').default, name: 'account.Domains'},
    {path: '/:lang?/account/payment', component: require('./components/account/PaymentsIndex.vue').default, name: 'account.Payment'},
    {path: '/:lang?/account/vps', component: require('./components/account/VpsIndex.vue').default, name: 'account.VPS'},
    {path: '/:lang?/account/vps/manager', component: require('./components/account/VpsManager.vue').default, name: 'account.VPS.Manager'},
]



Vue.component('users-list', require('./components/users/usersIndex.vue').default);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

//Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
Vue.use(VueRouter);

const router = new VueRouter({ routes, mode:'history' })

import _ from 'lodash'

Vue.prototype.__ = str => _.get(window.i18n, str);
Vue.prototype.t = function (str) {
    let val = _.get(window.i18n.user, str);
    if (val) return val;
    return str;
}

Vue.prototype.lang = window.app.lang;


function format_money(amount) {
    return '$'+parseFloat(amount).toFixed(2);
}


/*
const app = new Vue({
    el: '#app',
    router
});
*/
const app = new Vue({
    vuetify: new Vuetify(),
    router
}).$mount('#app');

function load_balance() {
    let zone = '';
    if(app.$router.history.current.name)  {
        zone = app.$router.history.current.name.split('.')[0];
    }
    if (app.$router.history.current.name && zone!=='account') return;
    axios.get('/api/v1/account/balance/')
        .then(function (resp) {
            document.getElementById('balance').innerHTML=format_money(resp.data.amount) + ' '+ resp.data.tariff.title;
            window.userBalance = resp.data.amount;
            window.userActive = resp.data.active;
            let items = document.querySelectorAll('.user_menu>li.nav-item:not(.payment)');
            if (window.userActive===0) {
                Array.from(items, el=>el.classList.add('d-none'))
            } else {
                Array.from(items, el=>el.classList.remove('d-none'))
            }
        })
        .catch(function (resp) {
            //alert("Loading error");
        });
}



/*function isp_auth() {
    if(!app.$router.history.current.name) return;
    const zone = app.$router.history.current.name.split('.')[0];
    if (zone!=='account') return;
    axios.get('/api/v1/account/isp_auth/')
        .then(function (resp) {}).catch(function (resp) {});
}

isp_auth();
window.setInterval(isp_auth,300000);
*/
load_balance();
window.setInterval(load_balance,10000);

