<template>
    <div data-app>
        <div class="panel panel-default">
            <div class="panel-body">
                <div class="alert alert-success">{{ __("user.SSL_guide") }}</div>
                <v-data-table :headers="headers" :items="list" :items-per-page="50" class="evaluate-1" loading loading-text="Loading... Please wait">
                    <!-- Dialogs -->
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>{{ i18n.user.SSL_keys}}</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-btn color="error" class="mb-2 mr-2" @click="uploadConfig">
                                {{ i18n.user.Upload_config}}
                            </v-btn>
                            <v-dialog v-model="dialogNew" max-width="500px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                        {{ i18n.user.Add_generate_ssl}}
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title>
                                        <span class="headline">{{ formTitle }}</span>
                                    </v-card-title>
                                    <v-alert color="#f9c9c9" v-if="hasErrors">
                                        <div v-for="error in validation_errors">
                                            {{ error[0] }}
                                        </div>
                                    </v-alert>
                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-select v-model="editedItem.domain_id" @change="setSubdomains" :items="domains" item-value="elid" item-text="domain" :label="i18n.user.Domain" clearable></v-select>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="6">
                                                    <v-select v-model="editedItem.record_id" :items="subdomains" item-value="record_id" item-text="name" :label="i18n.user.Subdomain" clearable></v-select>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="6">
                                                    <v-checkbox v-model="editedItem.generate" :label="i18n.user.LetsEncrypt"  class="d-inline-block"></v-checkbox>
                                                    <v-tooltip left attach>
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon  v-on="on"  class="pb-1">mdi-help-circle</v-icon>
                                                        </template>
                                                        <span>{{ i18n.user.Generate_letsencrypt_ssl }}</span>
                                                    </v-tooltip>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12" v-if="!editedItem.generate">
                                                    <v-textarea v-model="editedItem.crt" class="cert" rows="8" :label="i18n.user.Certificate"></v-textarea>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12" v-if="!editedItem.generate">
                                                    <v-textarea v-model="editedItem.private" class="cert" rows="8" :label="i18n.user.Private_key"></v-textarea>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="closeNew">{{ i18n.user.Cancel }}</v-btn>
                                        <v-btn color="blue darken-1" text @click="saveNew">{{ i18n.user.Save }}</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <v-dialog v-model="dialog" max-width="500px">
                                <v-card>
                                    <v-card-title>
                                        <span class="headline">{{ formTitle }}</span>
                                    </v-card-title>
                                    <v-alert color="#f9c9c9" v-if="hasErrors">
                                        <div v-for="error in validation_errors">
                                            {{ error[0] }}
                                        </div>
                                    </v-alert>
                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-select v-model="editedItem.domain_id" @change="setSubdomains" :items="domains" item-value="elid" item-text="domain" :label="i18n.user.Domain" clearable></v-select>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="6">
                                                    <v-select v-model="editedItem.record_id" :items="subdomains" item-value="record_id" item-text="name" :label="i18n.user.Subdomain" clearable></v-select>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="6">
                                                    <v-checkbox v-model="editedItem.generate" :label="i18n.user.LetsEncrypt"  class="d-inline-block"></v-checkbox>
                                                    <v-tooltip left attach>
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon  v-on="on"  class="pb-1">mdi-help-circle</v-icon>
                                                        </template>
                                                        <span>{{ i18n.user.Generate_letsencrypt_ssl }}</span>
                                                    </v-tooltip>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-textarea v-model="editedItem.crt" class="cert" rows="8" :label="i18n.user.Certificate"></v-textarea>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-textarea v-model="editedItem.private" class="cert" rows="8" :label="i18n.user.Private_key"></v-textarea>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="close">{{ i18n.user.Cancel }}</v-btn>
                                        <v-btn color="blue darken-1" text @click="save">{{ i18n.user.Save }}</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <v-dialog v-model="dialogDelete" max-width="500px">
                                <v-card>
                                    <v-card-title class="headline">{{ i18n.user.You_want_remove }}</v-card-title>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="closeDelete">{{ i18n.user.Cancel }}</v-btn>
                                        <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{ i18n.user.ok }}</v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-toolbar>
                    </template>
                    <!-- Dialogs -->

                    <!-- Draggable -->
                    <template v-slot:body="props">
                            <tr v-for="(item, index) in list" :key="item.id">
                                <td>
                                    <span v-if="item.subdomain && item.subdomain!='@'">{{item.subdomain}}.</span>{{ item.domain }}
                                </td>
                                <td>
                                    {{ item.status }}
                                </td>
                                <td align="right">
                                    <v-icon v-if="item.generate" small class="m-2" @click="refreshItem(item)">mdi-refresh</v-icon>
                                    <v-icon small class="m-2" @click="editItem(item)">mdi-pencil</v-icon>
                                    <v-icon small class="m-2" @click="deleteItem(item)">mdi-delete</v-icon>
                                </td>
                            </tr>
                    </template>
                    <!-- Draggable -->

                    <template slot="headerCell" slot-scope="props">
                        <v-tooltip bottom>
                            <span slot="activator">
                            {{ props.header.text }}
                            </span>
                            <span>
                            {{ props.header.text }}
                            </span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </div>
        </div>
        <v-overlay :value="overlay" z-index="2000">
            <v-progress-circular indeterminate size="64" ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                dialog: false,
                dialogNew: false,
                dialogDelete: false,
                validation_errors: [],
                hasErrors: false,
                overlay: false,
                headers: [
                    { text: i18n.user.Domain, sortable: false, value: 'domain', width: '60%' },
                    { text: i18n.user.Status, sortable: false, value: 'status', width: '30%' },
                    { text: i18n.user.Actions, sortable: false, value: 'actions', width:'10%' }
                ],
                list: [],
                domains: [],
                all_subdomains: [],
                subdomains: [],
                editedIndex: -1,
                editedItem: {
                    domain: '',
                    domain_id: '',
                    record_id: '',
                    crt: '',
                    private: '',
                    generate: false,
                },
                defaultItem: {
                    domain: '',
                    domain_id: '',
                    record_id: '',
                    crt: '',
                    private: '',
                    generate: false,
                },
            }
        },
        computed: {
            formTitle () {
                return this.editedIndex === -1 ? i18n.user.New_item : i18n.user.Edit_item
            },
        },
        created() {
            this.getDomains();
        },
        mounted() {
            var app = this;
            axios.get('/api/v1/account/ssl_keys')
                .then(function (resp) {
                    app.list = resp.data;
                })
                .catch(function (resp) {
                    alert("Loading error");
                });
        },
        methods: {
            uploadConfig: function () {
                var app = this;
                app.overlay = true;
                axios.put('/api/v1/account/upload_config')
                    .then(function (resp) {
                        app.overlay = false;
                    })
                    .catch(function (resp) {
                        app.overlay = false;
                        //alert("Loading error");
                    });
            },
            setSubdomains: function (domain_id) {
                /*let domains = [{
                    record_id:0,
                    name:'@',
                }]
              this.subdomains = domains.concat(this.all_subdomains[domain_id]);*/
              this.subdomains = this.all_subdomains[domain_id];
            },
            getDomains: function () {
                var app = this;
                axios.get('/api/v1/account/sites')
                    .then(function (resp) {
                        app.domains = resp.data;
                        console.log(app.domains);
                    })
                    .catch(function (resp) {
                        alert("Loading error");
                    });
                axios.get('/api/v1/account/sites/subdomains/a')
                    .then(function (resp) {
                        app.all_subdomains = resp.data;
                    })
                    .catch(function (resp) {
                        alert("Loading error");
                    });
            },

            editItem (item) {
                this.editedIndex = this.list.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.setSubdomains(this.editedItem.domain_id);
                console.log(this.editedItem);
                this.dialog = true
            },

            deleteItem (item) {
                this.editedIndex = this.list.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialogDelete = true
            },
            refreshItem(item) {
                this.editedIndex = this.list.indexOf(item)
                this.editedItem = Object.assign({}, item)
                let app = this;
                app.overlay = true;
                axios.post('/api/v1/account/ssl_keys/' + app.editedItem.id + '/refresh')
                    .then(function (resp) {
                        app.editedItem = resp.data;
                        app.list[app.editedIndex] = app.editedItem;
                        app.overlay = false;
                    })
                    .catch(function (resp) {
                        alert("Loading error");
                        app.overlay = false;
                    });
            },
            deleteItemConfirm () {
                this.list.splice(this.editedIndex, 1)
                let app = this;
                app.overlay = true;
                axios.delete('/api/v1/account/ssl_keys/' + app.editedItem.id)
                    .then(function (resp) {
                        app.overlay = false;
                        app.dialogDelete = false;
                    })
                    .catch(function (resp) {
                        console.log(resp);
                        app.overlay = false;
                        app.dialogDelete = false;
                        alert("Remove item error");
                    });
                this.closeDelete()
            },
            getColor(active) {
                return active==1 ? 'text-success' : 'text-danger';
            },

            close () {
                this.dialog = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },

            closeNew () {
                this.dialogNew = false
                this.$nextTick(() => {
                    this.editedIndex = -1
                })
            },
            closeDelete () {
                this.dialogDelete = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },
            save () {
                if (this.editedIndex > -1) {
                    let app = this;
                    app.overlay = true;
                    console.log("ID:::" + this.editedItem.user);
                    axios.put('/api/v1/account/ssl_keys/' + app.editedItem.id,{
                        id: app.editedItem.id,
                        domain_id: app.editedItem.domain_id,
                        record_id: app.editedItem.record_id,
                        generate: app.editedItem.generate,
                        crt: app.editedItem.crt,
                        private: app.editedItem.private,
                    })
                        .then(function (resp) {
                            app.overlay = false;
                            this.close()
                        })
                        .catch(function (resp) {
                            app.validation_errors = resp.response.data.errors;
                            app.hasErrors = true;
                            app.overlay = false;
                            console.log(app.validation_errors);
                            //alert("Update item error");
                        });

                } else {
                    this.list.push(this.editedItem)
                }
                Object.assign(this.list[this.editedIndex], this.editedItem)
            },
            saveNew () {
                let app = this;
                app.overlay = true;
                //console.log(app.editedItem);
                axios.post('/api/v1/account/ssl_keys/create', {
                    id: app.editedItem.id,
                    domain_id: app.editedItem.domain_id,
                    record_id: app.editedItem.record_id,
                    generate: app.editedItem.generate,
                    crt: app.editedItem.crt,
                    private: app.editedItem.private,
                })
                    .then(function (resp) {
                        app.list = resp.data
                        app.validation_errors = [];
                        app.hasErrors = false;
                        app.editedItem = app.defaultItem;
                        app.overlay = false;
                        app.closeNew()
                    })
                    .catch(function (resp) {
                        app.validation_errors = resp.response.data.errors;
                        app.hasErrors = true;
                        app.overlay = false;
                        console.log(app.validation_errors);
                        //alert("Update item error");
                    });
            },
        }
    }
</script>
