<template>
    <div data-app>
        <div class="panel panel-default">
            <div class="panel-body">
                <v-data-table :headers="headers" hide-default-footer :items="list" class="evaluate-1" :loading="loading"
                              loading-text="Loading... Please wait">
                    <!-- Dialogs -->
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>{{ i18n.user.Tariffs}}</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-dialog v-model="dialogNew" max-width="500px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                        {{ i18n.user.New_item}}
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title>
                                        <span class="headline">{{ formTitle }}</span>
                                    </v-card-title>

                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-text-field v-model="editedItem.title" :label="i18n.user.Title"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="6">
                                                    <v-text-field v-model="editedItem.price" :label="i18n.user.Price"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="6">
                                                    <v-text-field v-model="editedItem.limit" :label="i18n.user.Sites"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-select v-model="editedItem.user" :items="users" item-value="id" item-text="login" :label="i18n.user.User" clearable></v-select>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-checkbox v-model="editedItem.restricted" :label="i18n.user.single_tariff"></v-checkbox>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-text-field v-model="editedItem.label" :label="i18n.user.Label_only_admins"></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="closeNew">{{ i18n.user.Cancel }}</v-btn>
                                        <v-btn color="blue darken-1" text @click="saveNew">{{ i18n.user.Save }}</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <v-dialog v-model="dialog" max-width="500px">
                                <v-card>
                                    <v-card-title>
                                        <span class="headline">{{ formTitle }}</span>
                                    </v-card-title>

                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-text-field v-model="editedItem.title" :label="i18n.user.Title"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="6">
                                                    <v-text-field v-model="editedItem.price" :label="i18n.user.Price"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="6">
                                                    <v-text-field v-model="editedItem.limit" :label="i18n.user.Sites"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-select v-model="editedItem.user" :items="users" item-value="id" item-text="login" :label="i18n.user.User" clearable></v-select>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-checkbox v-model="editedItem.restricted" :label="i18n.user.single_tariff"></v-checkbox>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-text-field v-model="editedItem.label" :label="i18n.user.Label_only_admins"></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="close">{{ i18n.user.Cancel }}</v-btn>
                                        <v-btn color="blue darken-1" text @click="save">{{ i18n.user.Save }}</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <v-dialog v-model="dialogDelete" max-width="500px">
                                <v-card>
                                    <v-card-title class="headline">{{ i18n.user.You_want_remove }}</v-card-title>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="closeDelete">{{ i18n.user.Cancel }}</v-btn>
                                        <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{ i18n.user.ok }}</v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-toolbar>
                    </template>
                    <!-- Dialogs -->

                    <!-- Draggable -->
                    <template v-slot:body="props">
                        <draggable v-model="list"  tag="tbody" @change="sort">
                            <tr v-for="(item, index) in list" :key="item.id">
                                <td><v-icon small class="page__grab-icon">mdi-arrow-all</v-icon></td>
                                <td>
                                    <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
                                    <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
                                </td>
                                <td> {{ item.title }} </td>
                                <td> {{ item.price }} </td>
                                <td> {{ item.limit }} </td>
                                <td> {{ item.label }} </td>

                            </tr>
                        </draggable>
                    </template>
                    <!-- Draggable -->

                    <template slot="headerCell" slot-scope="props">
                        <v-tooltip bottom>
                            <span slot="activator">
                            {{ props.header.text }}
                            </span>
                            <span>
                            {{ props.header.text }}
                            </span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                dialog: false,
                loading: true,
                dialogNew: false,
                dialogDelete: false,
                headers: [
                    { text: '#', sortable: false, value: 'id', width: '1%' },
                    { text: i18n.user.Actions, sortable: false, value: 'actions', width:'5%' },
                    { text: i18n.user.Title, sortable: false, value: 'title', width: '90%' },
                    { text: i18n.user.Price, sortable: false, value: 'price', width: '150px' },
                    { text: i18n.user.Sites, sortable: false, value: 'limit', width: '150px' },
                    { text: i18n.user.Label, sortable: false, value: 'label', width: '150px' }
                ],
                list: [],
                users: [],
                editedIndex: -1,
                editedItem: {
                    title: '',
                    price: '',
                    limit: '',
                    label: '',
                },
                defaultItem: {
                    title: '',
                    price: '',
                    limit: '',
                    label: '',
                },
            }
        },
        computed: {
            formTitle () {
                return this.editedIndex === -1 ? i18n.user.New_item : i18n.user.Edit_item
            },
        },
        created() {
            this.getUsers();
        },
        mounted() {
            var app = this;
            axios.get('/api/v1/tariff')
                .then(function (resp) {
                    app.list = resp.data;
                    app.loading = false;
                })
                .catch(function (resp) {
                    app.loading = false;
                    alert("Loading error");
                });
        },
        methods: {
            sort: function (evt) {
                let sort = [];
                for (let i in this.list) {
                    sort[i] = this.list[i].id;
                }
                axios.post('/api/v1/tariff/sort',{'ids':sort})
                    .then(function (resp) {
                    })
                    .catch(function (resp) {
                        alert("Sorting error");
                    });

            },
            getUsers: function () {
                axios.get('/api/v1/lite_users')
                    .then((response) => {
                        this.users  = response.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },

            editItem (item) {
                this.editedIndex = this.list.indexOf(item)
                this.editedItem = Object.assign({}, item)
                console.log(this.editedItem);
                this.dialog = true
            },

            deleteItem (item) {
                this.editedIndex = this.list.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialogDelete = true
            },

            deleteItemConfirm () {
                this.list.splice(this.editedIndex, 1)
                var app = this;
                axios.delete('/api/v1/tariff/' + app.editedItem.id)
                    .then(function (resp) {})
                    .catch(function (resp) {
                        console.log(resp);
                        alert("Remove item error");
                    });
                this.closeDelete()
            },

            getColor(active) {
                return active==1 ? 'text-success' : 'text-danger';
            },

            close () {
                this.dialog = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },

            closeNew () {
                this.dialogNew = false
                this.$nextTick(() => {
                    this.editedIndex = -1
                })
            },

            closeDelete () {
                this.dialogDelete = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },

            save () {
                if (this.editedIndex > -1) {

                    let app = this;
                    console.log("ID:::" + this.editedItem.user);
                    axios.put('/api/v1/tariff/' + app.editedItem.id,{
                        id: app.editedItem.id,
                        title: app.editedItem.title,
                        limit: app.editedItem.limit,
                        restricted: app.editedItem.restricted,
                        price: app.editedItem.price,
                        user: app.editedItem.user,
                        label: app.editedItem.label,
                    })
                        .then(function (resp) {
                        })
                        .catch(function (resp) {
                            console.log(resp);
                            alert("Update item error");
                        });

                } else {
                    this.list.push(this.editedItem)
                }
                Object.assign(this.list[this.editedIndex], this.editedItem)
                this.close()
            },
            saveNew () {
                let app = this;
                axios.post('/api/v1/tariff/create', {
                    id: app.editedItem.id,
                    title: app.editedItem.title,
                    limit: app.editedItem.limit,
                    restricted: app.editedItem.restricted ? app.editedItem.restricted : 0,
                    price: app.editedItem.price,
                    user: app.editedItem.user,
                    label: app.editedItem.label,
                })
                    .then(function (resp) {
                        app.list = resp.data
                        app.validation_errors = [];
                        app.hasErrors = false;
                        app.editedItem = app.defaultItem;
                        app.closeNew()
                    })
                    .catch(function (resp) {
                        app.validation_errors = resp.response.data.errors;
                        app.hasErrors = true;
                        console.log(app.validation_errors);
                        //alert("Update item error");
                    });
            },
        }
    }
</script>
