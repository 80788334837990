<template>
    <div data-app>
        <div class="panel panel-default">
            <div class="panel-body">
                <v-toolbar flat>
                    <v-toolbar-title>{{ i18n.user.Payments}}</v-toolbar-title>
                </v-toolbar>
                <v-divider class="mx-4" inset horizontal></v-divider>
                <v-alert color="#f9c9c9" v-if="hasErrors">
                    <div v-for="error in validation_errors">
                        {{ error[0] }}
                    </div>
                </v-alert>
                <div class="row">
                    <div class="col-md-3"><h5>{{ t('Tariff') }}</h5></div>
                    <div class="col-md-3">{{ i18n.user.Paid_to}}: {{ userTariff.end_date }}</div>
                    <div class="col-md-4"><h5>{{ i18n.user.Pay_method}}</h5></div>
                    <div class="col-md-2">{{ i18n.user.Amount}}: {{ userBalance }}</div>
                </div>
                <div class="row">
                    <div class="col-md-6"> <!-- userTariff.current_tariff == item.id ? "active" -->
                        <label v-for="tariff in tariffs" class="btn btn-light col-lg-3" :class="{ active:changeTariff.id==tariff.id}">
                            <input type="radio" name="tariff" autocomplete="off" :value="tariff.id" class="d-none" :checked="changeTariff.id == tariff.id" @change="changeTariff.id=tariff.id">
                            <span class="font-16">{{ t(tariff.title) }}</span><br>
                        $ {{ tariff.price }} / {{ t('month') }}
                        </label>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                        <v-checkbox v-model="changeTariff.autorenew" :label="t('Autorenew')"></v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-btn color="blue darken-1" class="btn-primary text-white mt-3 float-right" text @click="saveTariff">{{ t('Save')}}</v-btn>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="col-md-6">
                        <v-card>
                            <v-card-text>
                                <v-container v-if="on_btc" class="text-center">
                                    <h5 class="text-center">{{ t('Top_up_wallet')}} {{ btcTransaction.resources.app.payment.price}} {{ btcTransaction.resources.app.payment.sign}}</h5>
                                    <small>{{ t('Transfer_the_strictly_specified_amount')}}</small><br>
                                    <img :src="btcTransaction.form.qr">
                                    <v-text-field append-icon="mdi-content-copy" readonly v-clipboard="() => address" v-clipboard:success="() => { copied=true; }" v-model="address"></v-text-field>
                                    <v-chip close v-if="copied" @click:close="copied = false" class="position-absolute" style="right: 25px; bottom: 145px;">{{ t('Copied')}}</v-chip>
                                    <small class="my-2">{{ t('Pay_credited_after_3_confirmations') }}</small><br>
                                    <v-btn color="blue darken-1" class="btn-primary text-white" text @click="on_btc=false">{{ t('Return_to_pay_method') }}</v-btn>
                                </v-container>
                                <v-container v-if="!on_btc && !on_eth">
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-radio-group v-model="defaultItem.method"  row>
                                                <template v-for="item in paymentMethods">
                                                    <v-radio  :label="item.name" :value="item.id"></v-radio>
                                                </template>
                                            </v-radio-group>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-text-field v-model="defaultItem.amount" type="number" pattern="[0-9]+([\.,][0-9]+)?" step="0.1" min="5" reqiured :label="i18n.user.Amount" prepend-icon="$"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-btn color="blue darken-1" class="btn-primary text-white" text @click="pay(defaultItem)">{{ i18n.user.Top_up_balance }}</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>


                            </v-card-text>
                        </v-card>

                        <v-data-table :headers="headers" :items="list"  :dense="true" :items-per-page="50"  class="elevation-1" loading :loading-text=i18n.user.Empty  no-data-text="Empty">
                            <template slot="headerCell" slot-scope="props">
                                <v-tooltip bottom>
                                        <span slot="activator">
                                        {{ props.header.text }}
                                        </span>
                                    <span>
                                        {{ props.header.text }}
                                        </span>
                                </v-tooltip>
                            </template>
                            <!--template v-slot:item.active="{ item }">
                                <i class="fas fa-power-off" :class="getColor(item.active)"></i>
                            </template-->

                            <template v-slot:item.date="{ item }">
                                <small>{{ item.date | formatDateTime }}</small>
                            </template>

                            <template v-slot:item.status="{ item }">
                                <span v-if="item.status==1"><span  class="fas fa-check text-success"></span></span><span v-else><span  class="fas fa-times text-danger"></span> <small><br>{{ i18n.user[item.message] }}</small></span>
                            </template>

                            <template v-slot:item.actions="{ item }">
                                <v-icon small class="mr-2" @click="saveItem(item)">mdi-reload</v-icon>
                            </template>
                            <template v-slot:no-data>
                                <v-btn color="primary" @click="initialize">Reset</v-btn>
                            </template>

                        </v-data-table>
                    </div>
                </div>
            </div>
        </div>
        <v-overlay :value="overlay" z-index="2000">
            <v-progress-circular indeterminate size="64" ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>var copied;

import axios from "axios";

export default {
    data: function () {
        return {
            dialog: false,
            copied: false,
            dialogNew: false,
            validation_errors: [],
            hasErrors: false,
            overlay: false,
            userTariff: false,
            userBalance: false,
            btcTransaction: [],
            on_btc: false,
            on_eth: false,
            changeTariff: {
                id: 0,
                autorenew: true,
            },
            paymentMethods: [
                {name: 'Bitcoin', id: 'btc'},
                {name: 'Etherium', id: 'eth'},
            ],
            headers: [
                {text: '#', align: 'start', value: 'id', width: '30px', sortable: false},
                {text: i18n.user.Amount, value: 'amount', width: '32%', sortable: false},
                {text: i18n.user.Status, value: 'status', width: '24%', sortable: false},
                {text: i18n.user.Date, value: 'date', width: '32%', sortable: false},
            ],
            list: [],
            tariffs: [],
            editedIndex: -1,
            defaultItem: {
                method: 'btc',
                amount: 5
            },
        }
    },
    computed: {},
    created() {
    },
    mounted() {
        var app = this;
        axios.get('/api/v1/account/balance/')
            .then(function (resp) {
                app.userBalance = app.format_money(resp.data.amount);
            })
            .catch(function (resp) {
                console.error("Balance loading error");
            });
        axios.get('/api/v1/account/tariff/')
            .then(function (resp) {
                app.userTariff = resp.data;
                app.changeTariff.id = app.userTariff.tariff_id;
                app.changeTariff.autorenew = app.userTariff.auto_renew ? true : false;
            })
            .catch(function (resp) {
                console.error("Loading error");
            });
        axios.get('/api/v1/account/transactions/')
            .then(function (resp) {
                app.list = resp.data;
            })
            .catch(function (resp) {
                console.error("Loading error");
            });
        axios.get('/api/v1/account/tariffs/')
            .then(function (resp) {
                app.tariffs = resp.data;
            })
            .catch(function (resp) {
                console.error("Loading error");
            });
    },
    methods: {
        format_money(amount) {
            return '$' + parseFloat(amount).toFixed(2);
        },
        pay(item) {
            let app = this;
            app.overlay = true;
            axios.post('/api/v1/account/balance_charge/', {
                payment_type: item.method,
                sum: item.amount,
            })
                .then(function (resp) {
                    app.btcTransaction = resp.data;
                    app.validation_errors = [];
                    app.address = app.btcTransaction.form.pub_address;
                    app.on_btc = true;
                    app.hasErrors = false;
                    app.overlay = false;
                })
                .catch(function (resp) {
                    app.validation_errors = resp.response.data.errors;
                    app.hasErrors = true;
                    app.overlay = false;
                    console.error("Loading error");
                });
        },
        saveTariff() {
            let app = this;
            app.overlay = true;
            axios.post('/api/v1/account/tariff/' + app.changeTariff.id + '/update', {
                id: app.changeTariff.id,
                autorenew: app.changeTariff.autorenew,
            })
                .then(function (resp) {
                    app.userTariff = resp.data;
                    app.changeTariff.id = app.userTariff.tariff_id;
                    app.changeTariff.autorenew = app.userTariff.auto_renew ? true : false;
                    app.validation_errors = [];
                    app.hasErrors = false;
                    app.overlay = false;
                })
                .catch(function (resp) {
                    app.validation_errors = resp.response.data.errors;
                    app.hasErrors = true;
                    app.overlay = false;
                    console.log(app.validation_errors);
                    //alert("Update item error");
                });
        },
    }
}
</script>
