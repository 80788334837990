<template>
    <div data-app class="v-application">
        <div class="panel panel-default">
            <div class="panel-body">
                <div class="row">
                    <div class="col-md-12">
                        <v-card>
                            <v-card-title>
                                <span class="headline">{{ i18n.user.VPS_manage }}</span>
                            </v-card-title>

                            <v-card-text>
                                <v-alert color="#f9c9c9" v-if="hasErrors">
                                    <div v-for="error in validation_errors">
                                        {{ error[0] }}
                                    </div>
                                </v-alert>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12"><v-icon class=" btn pb-1"  @click="startVPS();">mdi-play-circle</v-icon></v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-dialog v-model="dialogDelete" max-width="500px">
                                <v-card>
                                    <v-card-title class="headline">{{ i18n.user.You_want_remove }}</v-card-title>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="white darken-1" class="btn-primary mr-2" text @click="closeDelete">{{ i18n.user.Cancel }}</v-btn>
                                        <v-btn color="white darken-1" class="btn-danger" text @click="deleteDomainConfirm">{{ i18n.user.Delete }}</v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="white darken-1" class="btn-danger mr-1" text @click="deleteDomain">{{ i18n.user.Delete }}</v-btn>
                                <v-btn color="white darken-1" class="primary" text @click="save">{{ i18n.user.Save }}</v-btn>
                            </v-card-actions>
                        </v-card>
                    </div>
                </div>
            </div>
        </div>
        <v-overlay :value="overlay" z-index="2000">
            <v-progress-circular indeterminate size="64" ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                dialog: false,
                dialogNew: false,
                dialogDelete: false,
                validation_errors: [],
                hasErrors: false,
                overlay: false,
                headers: [
                    { text: '#', align: 'start', value: 'id', width: '30px', sortable: false },
                    { text: i18n.user.Domain, value: 'domain', width: '24%', sortable: false },
                    { text: i18n.user.IP, value: 'ip', width: '24%', sortable: false },
                    { text: i18n.user.DNS, value: 'dns', width: '24%', sortable: false },
                    { text: i18n.user.Date, value: 'date', width: '24%', sortable: false },
                ],
                list: [],
                editedIndex: -1,
                editedItem: {
                },
            }
        },
        computed: {
            formTitle () {
                return i18n.user.Edit_item
            },
        },
        created() {
        },
        mounted() {
            var app = this;
            let id = app.$route.params.id;
            axios.get('/api/v1/account/vps/' + id+'/edit')
                .then(function (resp) {
                    app.editedItem = resp.data;
                })
                .catch(function () {
                    alert("Loading error")
                });

        },
        methods: {
            closeDelete () {
                this.dialogDelete = false
            },
        }
    }
</script>
