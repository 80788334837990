<template>
    <div data-app>
        <div class="panel panel-default">
            <div class="panel-body">
                <v-data-table :headers="headers" :items="list" :items-per-page="50" class="evaluate-1" :loading="loading" loading-text="Loading... Please wait">
                    <!-- Dialogs -->
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>{{ i18n.user.Proxy}}</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-dialog v-model="dialogNew" max-width="500px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                        {{ i18n.user.New_item}}
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title>
                                        <span class="headline">{{ formTitle }}</span>
                                    </v-card-title>

                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-text-field v-model="editedItem.name" :label="i18n.user.Name"></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="closeNew">{{ i18n.user.Cancel }}</v-btn>
                                        <v-btn color="blue darken-1" class="ml-1 btn-primary text-white" text @click="saveNew">{{ i18n.user.Save }}</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <v-dialog v-model="dialog" max-width="500px">
                                <v-card>
                                    <v-card-title>
                                        <span class="headline">{{ formTitle }}</span>
                                    </v-card-title>

                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-text-field v-model="editedItem.name" :label="i18n.user.Name"></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="close">{{ i18n.user.Cancel }}</v-btn>
                                        <v-btn color="blue darken-1" class="ml-1 btn-primary text-white" text @click="save">{{ i18n.user.Save }}</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <v-dialog v-model="dialogDelete" max-width="500px">
                                <v-card>
                                    <v-card-title class="headline">{{ i18n.user.You_want_remove }}</v-card-title>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="closeDelete">{{ i18n.user.Cancel }}</v-btn>
                                        <v-btn color="blue darken-1" class="ml-1 btn-primary text-white" text @click="deleteItemConfirm">{{ i18n.user.ok }}</v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-toolbar>
                    </template>
                    <!-- Dialogs -->

                    <template slot="headerCell" slot-scope="props">
                        <v-tooltip bottom>
                            <span slot="activator">
                            {{ props.header.text }}
                            </span>
                            <span>
                            {{ props.header.text }}
                            </span>
                        </v-tooltip>
                    </template>
                    <template v-slot:item.active="{ item }">
                            <i class="fas fa-power-off" :class="getColor(item.active)"></i>
                    </template>

                    <template v-slot:item.error="{ item }">
                        {{ item.error}} / 3
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
                        <v-icon small @click="deleteItem(item)" v-if="item.used_count==0">mdi-delete</v-icon>
                    </template>
                    <template v-slot:no-data>
                        <v-btn color="primary" @click="initialize">Reset</v-btn>
                    </template>

                </v-data-table>
                <v-alert color="#fff1dd">{{ i18n.user.Remove_proxy_group}}</v-alert>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                dialog: false,
                dialogNew: false,
                loading: true,
                dialogDelete: false,
                headers: [
                    { text: i18n.user.Name, value: 'name', width: '90%' },
                    { text: i18n.user.Used, value: 'used_count', width: '150px' },
                    { text: i18n.user.Actions, sortable: false, value: 'actions', width:'5%' }
                ],
                list: [],
                editedIndex: -1,
                editedItem: {
                    name: ''
                },
                defaultItem: {
                    name: ''
                },
            }
        },
        computed: {
            formTitle () {
                return this.editedIndex === -1 ? i18n.user.New_item : i18n.user.Edit_item
            },
        },
        created() {
        },
        mounted() {
            var app = this;
            axios.get('/api/v1/proxy_group')
                .then(function (resp) {
                    app.list = resp.data;
                    app.loading = false;
                })
                .catch(function (resp) {
                    app.loading = false;
                    alert("Loading error");
                });
        },
        methods: {
            editItem (item) {
                this.editedIndex = this.list.indexOf(item)
                this.editedItem = Object.assign({}, item)
                console.log(this.editedItem);
                this.dialog = true
            },

            deleteItem (item) {
                this.editedIndex = this.list.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialogDelete = true
            },

            deleteItemConfirm () {
                this.list.splice(this.editedIndex, 1)
                var app = this;
                axios.delete('/api/v1/proxy_group/' + app.editedItem.id)
                    .then(function (resp) {})
                    .catch(function (resp) {
                        console.log(resp);
                        alert("Remove item error");
                    });
                this.closeDelete()
            },

            getColor(active) {
                return active==1 ? 'text-success' : 'text-danger';
            },

            close () {
                this.dialog = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },

            closeNew () {
                this.dialogNew = false
                this.$nextTick(() => {
                    this.editedIndex = -1
                })
            },

            closeDelete () {
                this.dialogDelete = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },

            save () {
                if (this.editedIndex > -1) {

                    let app = this;
                    console.log("ID:::" + this.editedItem.user);
                    axios.put('/api/v1/proxy_group/' + app.editedItem.id,{
                        id: app.editedItem.id,
                        name: app.editedItem.name,
                    })
                        .then(function (resp) {
                        })
                        .catch(function (resp) {
                            console.log(resp);
                            alert("Update item error");
                        });

                } else {
                    this.list.push(this.editedItem)
                }
                Object.assign(this.list[this.editedIndex], this.editedItem)
                this.close()
            },
            saveNew () {
                    let app = this;
                    axios.post('/api/v1/proxy_group/create', {
                        id: app.editedItem.id,
                        name: app.editedItem.name,
                    })
                        .then(function (resp) {
                            app.list = resp.data
                        })
                        .catch(function (resp) {
                            console.log(resp);
                            alert("Update item error");
                        });
                this.editedItem = this.defaultItem;
                this.closeNew()
            },
        }
    }
</script>
