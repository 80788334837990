<template>
    <div data-app>
        <div class="panel panel-default">
            <div class="panel-body">
                <div class="alert alert-success">{{ __("user.Domains_guide") }}</div>
                <div class="row">
                    <div class="col-md-6">
                        <v-data-table :headers="headers" :items="list" :dense="true" :items-per-page="50"
                                      class="elevation-1" loading :loading-text=i18n.user.Empty no-data-text="Empty">
                            <template slot="headerCell" slot-scope="props">
                                <v-tooltip bottom>
                                    <span slot="activator">
                                    {{ props.header.text }}
                                    </span>
                                    <span>
                                    {{ props.header.text }}
                                    </span>
                                </v-tooltip>
                            </template>
                            <!--template v-slot:item.active="{ item }">
                                <i class="fas fa-power-off" :class="getColor(item.active)"></i>
                            </template-->

                            <template v-slot:item.date="{ item }">
                                <small>{{ item.date | formatDate }}</small>
                            </template>
                            <template v-slot:item.ns_1="{ item }">
                                <v-text-field v-model="item.ns_1" class="small"></v-text-field>
                            </template>
                            <template v-slot:item.ns_2="{ item }">
                                <v-text-field v-model="item.ns_2" class="small"></v-text-field>
                            </template>

                            <template v-slot:item.actions="{ item }">
                                <v-icon small class="mr-2" @click="saveItem(item)">mdi-reload</v-icon>
                            </template>
                            <template v-slot:no-data>
                                <v-btn color="primary" @click="initialize">Reset</v-btn>
                            </template>

                        </v-data-table>
                    </div>
                    <div class="col-md-6">
                        <v-card>
                            <v-card-title>
                                <span class="headline">{{ i18n.user.Reg_domain }}</span>
                            </v-card-title>

                            <v-card-text>
                                <v-alert color="#f9c9c9" v-if="hasErrors">
                                    <div v-for="error in validation_errors">
                                        {{ error[0] }}
                                    </div>
                                </v-alert>

                                <v-expansion-panels :value="0">
                                    <v-expansion-panel key="reg" flat="0">
                                        <v-expansion-panel-header>
                                            {{ i18n.user.Russian_register }}
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" sm="12" md="6">
                                                        <v-text-field v-model="regItem.domain" :label="i18n.user.Domain"
                                                                      v-on:keypress="reg_check=false;"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="6">
                                                        <v-autocomplete v-model="regItem.zone" :items="reg_list"
                                                                        :filter="zoneFilter" :label="i18n.user.Zone"
                                                                        attach>
                                                            <template slot="selection" slot-scope="data">
                                                                <small>{{ data.item.zone }}</small>
                                                            </template>
                                                            <template slot="item" slot-scope="data">
                                                                <v-row>
                                                                    <v-col cols="12" md="7"><small>{{
                                                                            data.item.zone
                                                                        }}</small></v-col>
                                                                    <v-col cols="12" md="5">
                                                                        <small><b>${{ data.item.price }}</b></small>
                                                                    </v-col>
                                                                </v-row>
                                                            </template>
                                                        </v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="12">
                                                        <v-btn v-if="!reg_check" color="blue darken-1"
                                                               class="btn-primary" text @click="checkRegNew">
                                                            {{ i18n.user.Check }}
                                                        </v-btn>
                                                        <v-btn v-if="reg_check" color="blue darken-1"
                                                               class="btn-primary" text @click="registerRegNew">
                                                            {{ i18n.user.Register }}
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel key="webnic" flat="1">
                                        <v-expansion-panel-header>
                                            {{ i18n.user.Malasian_register }}
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" sm="12" md="6">
                                                        <v-text-field v-model="webnicItem.domain"
                                                                      :label="i18n.user.Domain"
                                                                      v-on:keypress="webnic_check=false;"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="6">
                                                        <v-autocomplete v-model="webnicItem.zone" :items="webnic_list"
                                                                        :filter="zoneFilter" :label="i18n.user.Zone"
                                                                        attach>
                                                            <template slot="selection" slot-scope="data">
                                                                <small>{{ data.item.zone }}</small>
                                                            </template>
                                                            <template slot="item" slot-scope="data">
                                                                <v-row>
                                                                    <v-col cols="12" md="7"><small>{{
                                                                            data.item.zone
                                                                        }}</small></v-col>
                                                                    <v-col cols="12" md="5"><small><b>${{
                                                                            data.item.price
                                                                        }}</b></small></v-col>
                                                                </v-row>
                                                            </template>
                                                        </v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="12">
                                                        <v-btn v-if="!webnic_check" color="blue darken-1"
                                                               class="btn-primary" text @click="checkWebnicNew">
                                                            {{ i18n.user.Check }}
                                                        </v-btn>
                                                        <v-btn v-if="webnic_check" color="blue darken-1"
                                                               class="btn-primary"
                                                               text @click="registerWebnicNew">{{ i18n.user.Register }}
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>


                            </v-card-text>
                        </v-card>
                    </div>
                </div>
            </div>
        </div>
        <v-overlay :value="overlay" z-index="2000">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            dialog: false,
            dialogNew: false,
            validation_errors: [],
            hasErrors: false,
            overlay: false,
            reg_check: false,
            webnic_check: false,
            inviteLink: '',
            headers: [
                {text: i18n.user.Domain, value: 'domain', width: '32%', sortable: false},
                {text: i18n.user.DNS_1, value: 'ns_1', width: '24%', sortable: false},
                {text: i18n.user.DNS_2, value: 'ns_2', width: '24%', sortable: false},
                {text: i18n.user.Date, value: 'date', width: '19%', sortable: false},
                {text: '', sortable: false, value: 'actions', width: '2%'}
            ],
            list: [],
            registar_list: ['reg', 'webnic'],
            reg_list: [],
            webnic_list: [],
            editedIndex: -1,
            regItem: {
                ns_1: 'a.dnspod.com',
                ns_2: 'v.dnspod.com',
                domain: '',
            },
            webnicItem: {
                ns_1: 'a.dnspod.com',
                ns_2: 'v.dnspod.com',
                domain: '',
            },
            defaultItem: {
                ns_1: 'a.dnspod.com',
                ns_2: 'v.dnspod.com',
                domain: '',
            },
        }
    },
    computed: {},
    created() {
    },
    mounted() {
        var app = this;
        this.loadListDomains();
        axios.get('/api/v1/account/zones/reg')
            .then(function (resp) {
                app.reg_list = resp.data;
            })
            .catch(function (resp) {
                alert("Loading error");
            });
        axios.get('/api/v1/account/zones/webnic')
            .then(function (resp) {
                app.webnic_list = resp.data;
            })
            .catch(function (resp) {
                alert("Loading error");
            });
    },
    methods: {
        zoneFilter(item, queryText, itemText) {
            const textOne = item.zone.toLowerCase()
            const searchText = queryText.toLowerCase()

            return textOne.indexOf(searchText) == 0
        },
        loadListDomains() {
            var app = this;
            axios.get('/api/v1/account/domains/')
                .then(function (resp) {
                    app.list = resp.data;
                })
                .catch(function (resp) {
                    alert("Loading error");
                });
        },
        checkWebnicNew() {
            let app = this;
            app.overlay = true;
            axios.post('/api/v1/account/domains/check', {
                register: 'webnic',
                domain: app.webnicItem.domain,
                zone: app.webnicItem.zone,
            })
                .then(function (resp) {
                    app.webnic_check = resp.data.status;
                    app.hasErrors = false;
                    if (!app.webnic_check) {
                        app.validation_errors = resp.data.errors;
                        app.hasErrors = true;
                    } else {
                        app.validation_errors = [];
                    }
                    app.overlay = false;
                })
                .catch(function (resp) {
                    app.validation_errors = resp.response.data.errors;
                    app.hasErrors = true;
                    app.overlay = false;
                    console.log(app.validation_errors);
                    //alert("Update item error");
                });
        },
        registerWebnicNew() {
            let app = this;
            app.overlay = true;
            axios.post('/api/v1/account/domains/create', {
                register: 'webnic',
                domain: app.webnicItem.domain,
                zone: app.webnicItem.zone,
            })
                .then(function (resp) {
                    console.log(resp.data);
                    app.list = resp.data
                    app.validation_errors = [];
                    app.hasErrors = false;
                    app.webnicItem = app.defaultItem;
                    app.overlay = false;
                })
                .catch(function (resp) {
                    app.validation_errors = resp.response.data.errors;
                    app.hasErrors = true;
                    app.overlay = false;
                    console.log(app.validation_errors);
                    //alert("Update item error");
                });
        },
        checkRegNew() {
            let app = this;
            app.overlay = true;
            axios.post('/api/v1/account/domains/check', {
                register: 'reg',
                domain: app.regItem.domain,
                zone: app.regItem.zone,
            })
                .then(function (resp) {
                    app.reg_check = resp.data.status;
                    app.hasErrors = false;
                    if (!app.reg_check) {
                        app.validation_errors = resp.data.errors;
                        app.hasErrors = true;
                    } else {
                        app.validation_errors = [];
                    }
                    app.overlay = false;
                })
                .catch(function (resp) {
                    app.validation_errors = resp.response.data.errors;
                    app.hasErrors = true;
                    app.overlay = false;
                    console.log(app.validation_errors);
                    //alert("Update item error");
                });
        },
        registerRegNew() {
            let app = this;
            app.overlay = true;
            axios.post('/api/v1/account/domains/create', {
                register: 'reg',
                domain: app.regItem.domain,
                zone: app.regItem.zone,
            })
                .then(function (resp) {
                    console.log(resp.data);
                    app.list = resp.data
                    app.validation_errors = [];
                    app.hasErrors = false;
                    app.regItem = app.defaultItem;
                    app.overlay = false;
                })
                .catch(function (resp) {
                    app.validation_errors = resp.response.data.errors;
                    app.hasErrors = true;
                    app.overlay = false;
                    console.log(app.validation_errors);
                    //alert("Update item error");
                });
        },
        saveItem(item) {
            this.editedIndex = this.list.indexOf(item)
            let app = this;
            app.overlay = true;
            console.log(item);
            axios.post('/api/v1/account/domains/' + item.id + '/update', {
                id: item.id,
                ns_1: item.ns_1,
                ns_2: item.ns_2,
                register: item.register,
            })
                .then(function (resp) {
                    app.list[app.editedIndex] = resp.data;
                    app.validation_errors = [];
                    app.hasErrors = false;
                    app.overlay = false;
                })
                .catch(function (resp) {
                    app.validation_errors = resp.response.data.errors;
                    app.hasErrors = true;
                    app.overlay = false;
                    console.log(app.validation_errors);
                    //alert("Update item error");
                });
        },
    }
}
</script>
