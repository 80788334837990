<template>
    <div data-app>
        <div class="panel panel-default">
            <div class="panel-body">
                <v-card class="mb-3">
                    <v-card-title>
                        <span class="headline">{{ i18n.user.API }}</span>
                    </v-card-title>

                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field v-model="all_proxy" readonly :label="i18n.user.Proxy_all"
                                                  append-icon="mdi-content-copy" readonly v-clipboard="() => all_proxy"
                                                  v-clipboard:success="() => { copied_1=true; }"></v-text-field>
                                    <v-chip close v-if="copied_1" @click:close="copied_1 = false" class="position-absolute" style="right: 5px; bottom: 65px;">{{ t('Copied')}}</v-chip>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field v-model="current_proxy" readonly :label="i18n.user.Proxy_current"
                                                  append-icon="mdi-content-copy" readonly v-clipboard="() => current_proxy"
                                                  v-clipboard:success="() => { copied_2=true; }"></v-text-field>
                                    <v-chip close v-if="copied_2" @click:close="copied_2 = false" class="position-absolute" style="right: 5px; bottom: 65px;">{{ t('Copied')}}</v-chip>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            dialog: false,
            copied_1: false,
            copied_2: false,
            all_proxy: '',
            current_proxy: '',
        }
    },
    computed: {},
    created() {
    },
    mounted() {
        var app = this;
        axios.get('/api/v1/account/api')
            .then(function (resp) {
                app.all_proxy = resp.data.all_proxy;
                app.current_proxy = resp.data.current_proxy;
                console.log(app.all_proxy);
                console.log(app.current_proxy);
            })
            .catch(function (resp) {
                alert("Loading error");
            });
    },
    methods: {}
}
</script>
